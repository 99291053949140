/** @format */

import { Fragment } from 'react';
import ListItemButton, {
    ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Authorization from '../../utils/authorization';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import LogoutIcon from '../../assets/img/svg/drawer/logout.svg';
import {
    AddUserIcon,
    DashboardIcon,
    ViewUserIcon,
    ServiceAlertsIcon,
    CropStageIcon,
    CalenderIcon,
    DeviceIcon,
    CreateCalendarIcon,
    CreateUserManagement,
} from '../../utils/customSvg';
import { createSearchParams, useNavigate } from 'react-router-dom';
import CustomTooltip from '../ui/CustomTooltip';
import { Dayjs } from 'dayjs';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import { connect } from 'react-redux';
import { store } from '../../redux/store';
import { RESET_ACTION } from '../../redux/actions/calendar/action';

const iconColor = '#6e6e6e';
const hoverIconColor = '#fff';

interface ListItemButtonProps extends MuiListItemButtonProps {
    active?: string;
    logoutStyle?: boolean;
}

const StyledListItemButton = styled(ListItemButton)<ListItemButtonProps>(
    ({ theme, active, logoutStyle }) => ({
        //  margin: '10px 5px',
        // padding: '5px 15px',
        borderRadius: '5px',
        border: '1px solid transparent',
        flexGrow: 0,

        ...(active === 'false' && {
            '&:hover': {
                color: '#6E6E6E',
                background: '#FFFFFF 0% 0% no-repeat',
                boxShadow: '0px 3px 6px #00000029',
                border: '1px solid #B7B7B7',
                borderRadius: '5px',
            },
        }),

        ...(active === 'true' && {
            backgroundColor: '#206BDD',
            color: '#ffffff',
            img: {
                color: '#fff',
            },
            '&:hover': {
                backgroundColor: '#206BDD',
                //color: "#ffffff"
            },
        }),
    })
);
const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    '& .MuiTypography-root': {
        fontSize: '0.80rem',
    },
}));
interface IProps {
    open: boolean;
}
export const DrawerList = ({ open }: IProps) => {
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const history = useNavigate();

    const dayjs = require('dayjs');
    const currentDate = dayjs(Dayjs);

    const month = currentDate.month() + 1;
    const year = currentDate.year();
    const dateFilterValue = month + '-' + year;

    const activeUrl = (url: string) => {
        return window.location.pathname.indexOf(url) > -1 ? true : false;
    };
    const handleNavList = (url: string, params?: any) => {
        if (params) {
            history({
                pathname: `/${url}`,
                search: `?${createSearchParams(params)}`,
            });
        } else {
            history(`/${url}`);
        }
    };
    const handleLogout = () => {
        Authorization.logOut();
        store.dispatch(RESET_ACTION);
        history(`/`);
    };
    const sideMenuList = [
        {
            name: 'Dashboard',
            url: 'dashboard',
            icon: (
                <DashboardIcon
                    fillColor={
                        activeUrl('dashboard') ? hoverIconColor : iconColor
                    }
                />
            ),
        },
    ];

    const userMenuList = [
        {
            name: 'Create New Farmer',
            url: 'add-user',
            icon: (
                <AddUserIcon
                    fillColor={
                        activeUrl('add-user') ? hoverIconColor : iconColor
                    }
                />
            ),
        },
        {
            name: 'Create New Crop Calendar',
            url: 'add-crop',
            icon: (
                <CreateCalendarIcon
                    fillColor={
                        activeUrl('add-crop') ? hoverIconColor : iconColor
                    }></CreateCalendarIcon>
            ),
        },

        {
            name: 'User List',
            url: 'users',
            icon: (
                <ViewUserIcon
                    fillColor={activeUrl('users') ? hoverIconColor : iconColor}
                />
            ),
        },
    ];
    const otherMenuList = [
        {
            name: 'Service Alerts',
            url: 'service-alerts/disease',
            icon: (
                <ServiceAlertsIcon
                    fillColor={
                        activeUrl('service-alerts/disease')
                            ? hoverIconColor
                            : iconColor
                    }
                />
            ),
            params: {
                isfiltered: false,
                monthyearfilter: dateFilterValue,
                risktype: 'ALL',
                status: 'ALL',
            },
        },
        {
            name: 'Crop Calendar',
            url: 'crop-calendar',
            icon: (
                <CalenderIcon
                    fillColor={
                        activeUrl('crop-calendar') ? hoverIconColor : iconColor
                    }
                />
            ),
        },
        {
            name: 'Crop Stage wise Advisory',
            url: 'cswa',
            icon: (
                <CropStageIcon
                    fillColor={activeUrl('cswa') ? hoverIconColor : iconColor}
                />
            ),
        },
        {
            name: 'Device Management',
            url: 'device',
            icon: (
                <DeviceIcon
                    fillColor={activeUrl('device') ? hoverIconColor : iconColor}
                />
            ),
        },

        {
            name: 'Master Data Management',
            url: 'user-management',
            icon: (
                <CreateUserManagement
                    fillColor={
                        activeUrl('user-management')
                            ? hoverIconColor
                            : iconColor
                    }
                />
            ),
        },
    ];

    const condOpen = !open ? true : false;

    return (
        <Fragment>
            <Fragment>
                {/* Dashboard */}
                <CustomTooltip
                    title={'Dashboard'}
                    placement='right'
                    open={activeUrl('dashboard') ? false : condOpen}
                    isArrow={true}
                    issideba='true'
                    style={{
                        color: '#1A1A1A',
                        backgroundColor: '#DEDEDE',
                    }}>
                    <StyledListItemButton
                        active={activeUrl('dashboard').toString()}
                        onClick={() => handleNavList('dashboard')}>
                        <ListItemIcon>
                            <DashboardIcon
                                fillColor={
                                    activeUrl('dashboard')
                                        ? hoverIconColor
                                        : iconColor
                                }
                            />
                        </ListItemIcon>
                        <StyledListItemText primary='Dashboard' />
                    </StyledListItemButton>
                </CustomTooltip>
                <Divider sx={{ my: 1 }} />
                {/* User Management */}

                {userMenuList.map((value: any) => {
                    if (value.url === 'add-user' || value.url === 'add-crop') {
                        if (
                            loggedUser?.roleid === 'ADMIN'
                        ) {
                            return (
                                <CustomTooltip
                                    key={value.name}
                                    title={value.name}
                                    placement='right'
                                    open={
                                        activeUrl(value.url) ? false : condOpen
                                    }
                                    isArrow={true}
                                    issideba='true'
                                    style={{
                                        color: '#1A1A1A',
                                        backgroundColor: '#DEDEDE',
                                    }}>
                                    <StyledListItemButton
                                        key={value.name}
                                        active={activeUrl(value.url).toString()}
                                        onClick={() =>
                                            handleNavList(value.url)
                                        }>
                                        <ListItemIcon>
                                            {value.icon}
                                        </ListItemIcon>
                                        <StyledListItemText
                                            primary={value.name}
                                        />
                                    </StyledListItemButton>
                                </CustomTooltip>
                            );
                        }
                    }

                    if (value.url === 'add-user') {
                        if (
                            loggedUser?.roleid === 'SUPER_ADMIN'
                        ) {
                            return (
                                <CustomTooltip
                                    key={value.name}
                                    title={value.name}
                                    placement='right'
                                    open={
                                        activeUrl(value.url) ? false : condOpen
                                    }
                                    isArrow={true}
                                    issideba='true'
                                    style={{
                                        color: '#1A1A1A',
                                        backgroundColor: '#DEDEDE',
                                    }}>
                                    <StyledListItemButton
                                        key={value.name}
                                        active={activeUrl(value.url).toString()}
                                        onClick={() =>
                                            handleNavList(value.url)
                                        }>
                                        <ListItemIcon>
                                            {value.icon}
                                        </ListItemIcon>
                                        <StyledListItemText
                                            primary={value.name}
                                        />
                                    </StyledListItemButton>
                                </CustomTooltip>
                            );
                        }
                    }
                    


                    if (value.url !== 'add-user' && value.url !== 'add-crop') {
                        return (
                            <CustomTooltip
                                key={value.name}
                                title={value.name}
                                placement='right'
                                open={activeUrl(value.url) ? false : condOpen}
                                isArrow={true}
                                issideba='true'
                                style={{
                                    color: '#1A1A1A',
                                    backgroundColor: '#DEDEDE',
                                }}>
                                <StyledListItemButton
                                    key={value.name}
                                    active={activeUrl(value.url).toString()}
                                    onClick={() => handleNavList(value.url)}>
                                    <ListItemIcon>{value.icon}</ListItemIcon>
                                    <StyledListItemText primary={value.name} />
                                </StyledListItemButton>
                            </CustomTooltip>
                        );
                    }
                    return '';
                })}
                <Divider sx={{ my: 1 }} />
            </Fragment>
            <Fragment>
                {/* Other Features */}

                {otherMenuList.map((value: any) => {
                    if (value.url === 'user-management') {
                        if (
                            loggedUser?.roleid === 'ADMIN' ||
                            loggedUser?.roleid === 'SUPER_ADMIN'
                        ) {
                            return (
                                <CustomTooltip
                                    key={value.name}
                                    title={value.name}
                                    placement='right'
                                    open={
                                        activeUrl(value.url) ? false : condOpen
                                    }
                                    isArrow={true}
                                    issideba='true'
                                    style={{
                                        color: '#1A1A1A',
                                        backgroundColor: '#DEDEDE',
                                    }}>
                                    <StyledListItemButton
                                        key={value.name}
                                        active={activeUrl(value.url).toString()}
                                        onClick={() =>
                                            handleNavList(
                                                value.url,
                                                value.params
                                            )
                                        }>
                                        <ListItemIcon>
                                            {value.icon}
                                        </ListItemIcon>
                                        <StyledListItemText
                                            primary={value.name}
                                        />
                                    </StyledListItemButton>
                                </CustomTooltip>
                            );
                        }
                    }
                    if (value.url !== 'user-management') {
                        return (
                            <CustomTooltip
                                key={value.name}
                                title={value.name}
                                placement='right'
                                open={activeUrl(value.url) ? false : condOpen}
                                isArrow={true}
                                issideba='true'
                                style={{
                                    color: '#1A1A1A',
                                    backgroundColor: '#DEDEDE',
                                }}>
                                <StyledListItemButton
                                    key={value.name}
                                    active={activeUrl(value.url).toString()}
                                    onClick={() =>
                                        handleNavList(value.url, value.params)
                                    }>
                                    <ListItemIcon>{value.icon}</ListItemIcon>
                                    <StyledListItemText primary={value.name} />
                                </StyledListItemButton>
                            </CustomTooltip>
                        );
                    }
                    return '';
                })}
            </Fragment>
            {/* Logout */}
            <Fragment>
                <CustomTooltip
                    title={'Logout'}
                    placement='right'
                    open={condOpen}
                    isArrow={true}
                    issideba='true'
                    style={{
                        color: '#1A1A1A',
                        backgroundColor: '#DEDEDE',
                    }}>
                    <StyledListItemButton
                        sx={{ marginTop: 'auto' }}
                        onClick={() => handleLogout()}>
                        <ListItemIcon>
                            <img src={LogoutIcon} alt='LOGOUT ICON' />
                        </ListItemIcon>
                        <StyledListItemText primary='Logout' />
                    </StyledListItemButton>
                </CustomTooltip>
            </Fragment>
        </Fragment>
    );
};
const mapStateToProps = (
    { calendar: { calendarValue } }: any,
    {
        createUser: {
            landCropObj,
            acreage,
            basicInfo,
            locationMapping,
            dataPrivacyConsent,
            dataConsent,
        },
    }: any,
    { state }: any
) => {
    return {
        calendarval: calendarValue,
        basicInfo: basicInfo,
        locationMapping: locationMapping,
        landcropobj: landCropObj,
        totalAcreage: acreage,
        dataPrivacyConsent: dataPrivacyConsent,
        dataConsent: dataConsent,
        selectedCrop: state.crop.selectedCrop,
        subStages: state.crop.cropSubstages,
        season: state.crop.season,
        year: state.crop.year,
        seasonStartDate: state.crop.seasonStartDate,
        seasonEndDate: state.crop.seasonEndDate,
        fromDate: state.crop.fromDate,
        toDate: state.crop.toDate,
        countryDetails: state.crop.countryDetails,
        geolevel1Details: state.crop.geolevel1Details,
        variety: state.crop.variety,
        calendarname: state.crop.calendarname,
    };
};

export default connect(mapStateToProps)(DrawerList);
