/** @format */

import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import {
    Autocomplete,
    Box,
    Button,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import './index.scss';
import TextField from '@mui/material/TextField';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { Alert } from '../../utils/toastr';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const style = {
    position: 'absolute' as 'absolute',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '90%',
    top: '50%',
    left: '43%',
    transform: 'translate(-39%, -48%)',
    width: '67%',
    paddingTop: '3%',
    paddingBottom: '2%',
    overflowX: 'auto',
    borderRadius: 4,
};

const useStyles = makeStyles({
    root: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#10384f',
        color: 'white',
        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#2e7d32',
            color: 'white',
        },
    },

    menu: {
        display: 'block',
        lineHeight: '12.5px',
        padding: '4px',
        paddingLeft: '15px',
    },
    checkbox: {
        display: 'flex',
        padding: '3px',
    },
});

const MenuProps = {
    PaperProps: {
        style: {
            height: 66,
        },
    },
};

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function EditUserDetails(props: any) {
    const classes = useStyles();
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    console.log('rpop', props.userDetail);
    const subscriptionarray = ['SMS', 'WhatsApp'];
    const forecastarray = ['Daily Forecast Alert', 'Yesterday Forecast Alert'];

    const [flag, setFlag] = React.useState(true);
    const [buttonText, setButtonText] = React.useState('edit details');
    const [textFieldDisable, setTextFieldDisable] = React.useState(true);
    const [subscriptionArray, setSubscriptionArray] = useState<any[]>(() => {
        let subList: any[] = [];
        if (props.userDetail.smssubscription) subList.push('SMS');
        if (props.userDetail.whatsappsubscription) subList.push('WhatsApp');
        return subList;
    });

    const [forecastArray, setForecastArray] = useState<any[]>(() => {
        let foreList: any[] = [];
        if (props.userDetail.dailyforecastalert)
            foreList.push('Daily Forecast Alert');
        if (props.userDetail.yesterdayforecastalert)
            foreList.push('Yesterday Forecast Alert');
        return foreList;
    });
    const [locationKey, setLocationKey] = useState<any>(Math.random());

    const [roleIDArray, setRoleIDArray] = useState<any[]>(
        props.valueMap?.get(props.userDetail.lineofbusiness)
    );
    const [userType, setUserType] = useState<String>(props.userDetail.usertype);
    const [roleID, setRoleID] = useState<String>(props.userDetail.roleid);
    console.log('role', props.userDetail.roleid);
    const [roleName, setRoleName] = useState<String>(props.userDetail.rolename);
    const [status, setStatus] = useState<String>(props.userDetail.status);
    const [language, setLanguage] = useState<String>(
        props.userDetail.defaultlanguage
    );
    const [firstName, setFirstName] = useState<String>(
        props.userDetail.firstname
    );
    const [lastName, setLastName] = useState<String>(props.userDetail.lastname);
    const [email, setEmail] = useState<any>(props.userDetail.email);
    const [phoneNum, setPhoneNum] = useState<any>(props.userDetail.phonenumber);
    const [lob, setLob] = useState<any>(props.userDetail.lineofbusiness);
    const [password, setPassword] = useState<any>('');
    const [state, setState] = useState<any>(
        props.userDetail.assignedgeolevel1
            ? props.userDetail.assignedgeolevel1.map(
                  (loc: any) => loc.lochiergeodesc
              )
            : []
    );
    const [showPassword, setShowPassword] = useState(false);

    const [location, setLocation] = useState<any>(
        props.userDetail.assignedloc
            ? props.userDetail.assignedloc.map((loc: any) => loc.lochiergeodesc)
            : []
    );

    const [selectedLocation, setSelectedLocation] = useState<any[]>(() =>
        props.userDetail.assignedloc
            ? props.userDetail.assignedloc.map((loc: any) => ({
                  'locationCode': loc.lochiergeocode,
                  'locationDesc': loc.lochiergeodesc,
              }))
            : []
    );

    const [selectedStateLocation, setSelectedStateLocation] = useState<any[]>(
        () =>
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map((state: any) => ({
                      'locationCode': state.lochiergeocode,
                      'locationDesc': state.lochiergeodesc,
                  }))
                : []
    );

    const [selectedLocationCodes, setSelectedLocationCodes] = useState<any[]>(
        props.userDetail.assignedloc
            ? props.userDetail.assignedloc.map((loc: any) => loc.lochiergeocode)
            : []
    );

    const [selectedStateCodes, setSelectedStateCodes] = useState<any[]>(
        props.userDetail.assignedgeolevel1
            ? props.userDetail.assignedgeolevel1.map(
                  (loc: any) => loc.lochiergeocode
              )
            : []
    );
    console.log('nkdkkc', selectedStateCodes);

    useEffect(() => {
        setFirstName(props.userDetail.firstname);
        setLastName(props.userDetail.lastname);
        setEmail(props.userDetail.email);
        setPhoneNum(props.userDetail.phonenumber);
        setLob(props.userDetail.lineofbusiness);
        setState(
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map(
                      (loc: any) => loc.lochiergeodesc
                  )
                : []
        );
        setUserType(props.userDetail.usertype);
        setRoleID(props.userDetail.roleid);
        setStatus(props.userDetail.status);
        setLanguage(props.userDetail.defaultlanguage);
        setPassword('');

        setFlag(true);
        setButtonText('edit details');
        setTextFieldDisable(true);
        setSubscriptionArray(() => {
            let subList: any[] = [];
            if (props.userDetail.smssubscription) subList.push('SMS');
            if (props.userDetail.whatsappsubscription) subList.push('WhatsApp');
            return subList;
        });

        setForecastArray(() => {
            let foreList: any[] = [];
            if (props.userDetail.dailyforecastalert)
                foreList.push('Daily Forecast Alert');
            if (props.userDetail.yesterdayforecastalert)
                foreList.push('Yesterday Forecast Alert');
            return foreList;
        });
        setRoleIDArray(props.valueMap?.get(props.userDetail.lineofbusiness));
        setLocation(
            props.userDetail.assignedloc
                ? props.userDetail.assignedloc.map(
                      (loc: any) => loc.lochiergeodesc
                  )
                : []
        );
        setSelectedLocation(() =>
            props.userDetail.assignedloc
                ? props.userDetail.assignedloc.map((loc: any) => ({
                      'locationCode': loc.lochiergeocode,
                      'locationDesc': loc.lochiergeodesc,
                  }))
                : []
        );
        setSelectedStateLocation(() =>
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map((state: any) => ({
                      'locationCode': state.lochiergeocode,
                      'locationDesc': state.lochiergeodesc,
                  }))
                : []
        );

        setSelectedLocationCodes(
            props.userDetail.assignedloc
                ? props.userDetail.assignedloc.map(
                      (loc: any) => loc.lochiergeocode
                  )
                : []
        );

        setSelectedStateCodes(
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map(
                      (loc: any) => loc.lochiergeocode
                  )
                : []
        );
    }, [props.userDetail]);

    const handleFirstNameChange = (event: any) => {
        setFirstName(event.target.value);
    };

    const handleLastNameChange = (event: any) => {
        setLastName(event.target.value);
    };

    const handleEmailChange = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: any) => {
        setPhoneNum(event.target.value);
    };

    const handleRoleChange = (event: any) => {
        setRoleID(event.target.value);
        setLocationKey(Math.random());
        setSelectedStateLocation([]);
        setSelectedStateCodes([]);
        setSelectedLocation([]);
        setSelectedLocationCodes([]);
    };

    const handleStatusChange = (event: any) => {
        setStatus(event.target.value);
    };

    const handleLanguageChange = (event: any) => {
        setLanguage(event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setPassword(event?.target.value);
    };

    const handleTypeChange = (event: any) => {
        setUserType(event.target.value);
    };

    const handleClick = () => {
        props.setErrorMsgAdmin('');
        if (buttonText === 'save') {
            let errorMessage = validateError();
            if (errorMessage === '') {
                saveUser();
                setButtonText('edit details');
                setFlag(!flag);
                props.setErrorMsgAdmin('');
            } else {
                props.setErrorMsgAdmin(errorMessage);
            }
        } else if (buttonText === 'edit details') {
            setTextFieldDisable(false);
            props.valueMap?.get(props.userDetail.lineofbusiness);
            setButtonText('save');
            setFlag(!flag);
        }
    };

    function saveUser() {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        var param = {
            countrycode: props.userDetail.countrycode,
            userid: props.userDetail.userid,
            firstname: firstName,
            lastname: lastName,
            email: email,
            phonenumber: phoneNum,
            usertype: userType,
            role: roleID,
            status: status,
            defaultlanguage: language,
            lob: lob,
            smssubscription: subscriptionArray.includes('SMS'),
            whatsappsubscription: subscriptionArray.includes('WhatsApp'),
            dailyforecastalert: forecastArray.includes('Daily Forecast Alert'),
            yesterdayforecastalert: forecastArray.includes(
                'Yesterday Forecast Alert'
            ),
        };

        if(password !== ''){
            param={
                ...param,
                ...{password:password},
            }
        }

        if (roleID === 'TM_PL' || roleID === 'TM_SAM') {
            param = {
                ...param,
                ...{ 'selectedlocations': selectedStateCodes },
            };
        } else {
            param = {
                ...param,
                ...{ 'selectedlocations': selectedLocationCodes },
            };
        }

        if (roleID === 'ADMIN') {
            param = {
                ...param,
                ...{ 'selectedlocations': [] },
            };
        }

        saveOrUpdateRequest({
            url: 'user/update',
            method: 'PUT',
            data: param,
        })
            .then((response: any) => {
                if (response.status === 200) {
                    Alert('success', 'User Data is Saved');
                    props.setEditUserDetailsModal(false);
                    setTextFieldDisable(true);
                    props.userListData();
                }
            })

            .catch((error: any) => {
                Alert(
                    'error',
                    'Error in saving the data . Please contact adminstrator'
                );
            });
    }

    const handleClosePopup = () => {
        setButtonText('edit details');
        setFlag(true);
        setTextFieldDisable(true);
        props.setErrorMsgAdmin('');
        props.setEditUserDetailsModal(false);
        setFirstName(props.userDetail.firstname);
        setLastName(props.userDetail.lastname);
        setEmail(props.userDetail.email);
        setPhoneNum(props.userDetail.phonenumber);
        setLob(props.userDetail.lineofbusiness);
        setState(
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map(
                      (loc: any) => loc.lochiergeodesc
                  )
                : []
        );

        setSelectedLocation(() =>
            props.userDetail.assignedloc
                ? props.userDetail.assignedloc.map((loc: any) => ({
                      'locationCode': loc.lochiergeocode,
                      'locationDesc': loc.lochiergeodesc,
                  }))
                : []
        );
        setSelectedStateLocation(() =>
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map((state: any) => ({
                      'locationCode': state.lochiergeocode,
                      'locationDesc': state.lochiergeodesc,
                  }))
                : []
        );
        setUserType(props.userDetail.usertype);
        setRoleID(props.userDetail.roleid);
        setStatus(props.userDetail.status);
        setLanguage(props.userDetail.defaultlanguage);
        setPassword('');

        setSelectedLocationCodes(
            props.userDetail.assignedloc
                ? props.userDetail.assignedloc.map(
                      (loc: any) => loc.lochiergeocode
                  )
                : []
        );

        setSelectedStateCodes(
            props.userDetail.assignedgeolevel1
                ? props.userDetail.assignedgeolevel1.map(
                      (loc: any) => loc.lochiergeocode
                  )
                : []
        );
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    function showRoleName() {
        let rolename = '';
        roleIDArray?.forEach((role: any) => {
            if (role.rolelabel === props.userDetail.roleid) {
                rolename = role.roledescription;
            }
        });
        return rolename;
    }

    const handleSubscriptionChange = (
        event: SelectChangeEvent<typeof subscriptionArray>
    ) => {
        const {
            target: { value },
        } = event;
        setSubscriptionArray(
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const handleForecastChange = (
        event: SelectChangeEvent<typeof forecastArray>
    ) => {
        const {
            target: { value },
        } = event;
        setForecastArray(typeof value === 'string' ? value.split(',') : value);
    };

    const validateError = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let error = [];
        let errorMessage = '';
        var mailReg = new RegExp(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        );
        /*
    The combination means, email address must start with “_A-Za-z0-9-\+” ,
    optional follow by “.[_A-Za-z0-9-]“, and end with a “@” symbol.
    The email’s domain name must start with “A-Za-z0-9-”,
    follow by first level Tld (.com, .net) “.[A-Za-z0-9]” and
    optional follow by a second level Tld (.com.au, .com.my) “\.[A-Za-z]{2,}”,
     where second level Tld must start with a dot “.”
      and length must equal or more than 2 characters */

        if (firstName.length === 0) {
            error.push('First name is Required');
        }

        if (lastName.length === 0) {
            error.push('Last name is Required');
        }

        if (!mailReg.test(email) || email.length === 0) {
            error.push('Invalid EmailID');
        }

        var reg = new RegExp('^[0-9]+$');

        if (props.userDetail.countrycode === 'IN') {
            if (!reg.test(phoneNum) || phoneNum.length !== 10) {
                error.push('Invalid phone number');
            }
        }

        if (props.userDetail.countrycode === 'TH') {
            if (!reg.test(phoneNum) || phoneNum.length !== 9) {
                error.push('Invalid phone number');
            }
        }

        if(roleID.length === 0){
            error.push('Please select a Role')
        }

        if (roleID !== 'ADMIN' && roleID.length !== 0) {
            if (
                (roleID === 'TM_PL' || roleID === 'TM_SAM') &&
                selectedStateCodes.length === 0
            ) {
                error.push('Please select a Location');
            } else if (!(roleID === 'TM_PL' || roleID === 'TM_SAM') && selectedLocationCodes.length === 0) {
                error.push('Please select a Location');
            }
        }

        error.forEach((err: string) => {
            errorMessage += err + ',';
        });

        return errorMessage;
    };

    function getLocationDropDown(roleId: String) {
        if (
            roleId !== 'select' &&
            roleId !== '' &&
            (roleId === 'TM_PL' || roleId === 'TM_SAM' || roleID === 'TL')
        ) {
            return (
                <div>
                    {props.userDetail.countrycode === 'IN' ? (
                        <div className='usermgt-headerfont'>State</div>
                    ) : (
                        <div className='usermgt-headerfont'>Province</div>
                    )}
                    <div>
                        {buttonText === 'edit details' ? (
                            <TextField
                                disabled={textFieldDisable}
                                className='usermgt-textfont'
                                InputProps={{
                                    classes: {
                                        input: props.classes.resize,
                                    },
                                }}
                                value={state}
                            />
                        ) : (
                            <Autocomplete
                                className='usermgmt-user-type-width'
                                multiple
                                id='checkboxes-tags-demo'
                                key={locationKey}
                                options={props.locationArray}
                                disableCloseOnSelect
                                getOptionLabel={(location) =>
                                    location.locationDesc
                                }
                                value={selectedStateLocation}
                                onChange={(
                                    event: any,
                                    value: any,
                                    reason: string
                                ) => {
                                    let selectedLocationTemp: any[] = [];
                                    let selectedLocationCodeTemp: any[] = [];
                                    let selectedLocationDescTemp: any[] = [];
                                    value.forEach((element: any) => {
                                        selectedLocationTemp.push({
                                            'locationCode':
                                                element.locationCode,
                                            'locationDesc':
                                                element.locationDesc,
                                        });
                                        selectedLocationCodeTemp.push(
                                            element.locationCode
                                        );
                                        selectedLocationDescTemp.push(
                                            element.locationDesc
                                        );
                                    });
                                    setSelectedStateLocation(
                                        selectedLocationTemp
                                    );
                                    setSelectedStateCodes(
                                        selectedLocationCodeTemp
                                    );
                                    setState(selectedLocationDescTemp);
                                }}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            className={classes.checkbox}
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{
                                                marginRight: 8,
                                            }}
                                            checked={selectedStateCodes.includes(
                                                option.locationCode
                                            )}
                                        />
                                        {option.locationDesc}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        )}
                    </div>
                </div>
            );
        } else if (
            roleId !== 'select' &&
            roleId !== '' &&
            roleId !== 'TM_PL' &&
            roleID !== 'TL' &&
            roleId !== 'TM_SAM' &&
            roleId !== 'ADMIN'
        ) {
            return (
                <div>
                    {props.userDetail.countrycode === 'IN' ? (
                        <div>
                            <div className='usermgt-headerfont'>Village</div>
                            {buttonText === 'edit details' ? (
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={location}
                                />
                            ) : (
                                <div>
                                    <Autocomplete
                                        className='usermgmt-user-type-width'
                                        multiple
                                        id='checkboxes-tags-demo'
                                        key={locationKey}
                                        options={props.villageArray}
                                        disableCloseOnSelect
                                        value={selectedLocation}
                                        onChange={(
                                            event: any,
                                            value: any,
                                            reason: string
                                        ) => {
                                            console.log('village');
                                            console.log('villagevalue', value);

                                            let selectedLocationTemp: any[] = [];
                                            let selectedLocationCodeTemp: any[] = [];
                                            let selectedLocationDescTemp: any[] = [];
                                            value.forEach((element: any) => {
                                                selectedLocationTemp.push({
                                                    'locationCode':
                                                        element.locationCode,
                                                    'locationDesc':
                                                        element.locationDesc,
                                                });
                                                selectedLocationCodeTemp.push(
                                                    element.locationCode
                                                );
                                                selectedLocationDescTemp.push(
                                                    element.locationDesc
                                                );
                                            });
                                            setSelectedLocation(
                                                selectedLocationTemp
                                            );
                                            setSelectedLocationCodes(
                                                selectedLocationCodeTemp
                                            );
                                            setLocation(
                                                selectedLocationDescTemp
                                            );
                                        }}
                                        getOptionLabel={(village) =>
                                            village.locationDesc
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => (
                                            <li {...props}>
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{
                                                        marginRight: 8,
                                                    }}
                                                    checked={selectedLocationCodes.includes(
                                                        option.locationCode
                                                    )}
                                                />
                                                {option.locationDesc}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <div className='usermgt-headerfont'>
                                Sub District
                            </div>
                            {buttonText === 'edit details' ? (
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={location}
                                />
                            ) : (
                                <div>
                                    <Autocomplete
                                        className='usermgmt-user-type-width'
                                        multiple
                                        id='checkboxes-tags-demo'
                                        key={locationKey}
                                        options={props.subDistrictArray}
                                        disableCloseOnSelect
                                        value={selectedLocation}
                                        onChange={(
                                            event: any,
                                            value: any,
                                            reason: string
                                        ) => {
                                            let selectedLocationTemp: any[] = [];
                                            let selectedLocationCodeTemp: any[] = [];
                                            let selectedLocationDescTemp: any[] = [];
                                            value.forEach((element: any) => {
                                                selectedLocationTemp.push({
                                                    'locationCode':
                                                        element.locationCode,
                                                    'locationDesc':
                                                        element.locationDesc,
                                                });
                                                selectedLocationCodeTemp.push(
                                                    element.locationCode
                                                );
                                                selectedLocationDescTemp.push(
                                                    element.locationDesc
                                                );
                                            });
                                            setSelectedLocation(
                                                selectedLocationTemp
                                            );
                                            setSelectedLocationCodes(
                                                selectedLocationCodeTemp
                                            );
                                            setLocation(
                                                selectedLocationDescTemp
                                            );
                                        }}
                                        getOptionLabel={(sub) =>
                                            sub.locationDesc
                                        }
                                        renderOption={(
                                            props,
                                            option,
                                            { selected }
                                        ) => (
                                            <li {...props}>
                                                <Checkbox
                                                    className={classes.checkbox}
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{
                                                        marginRight: 8,
                                                    }}
                                                    checked={selectedLocationCodes.includes(
                                                        option.locationCode
                                                    )}
                                                />
                                                {option.locationDesc}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
            );
        }
        return <div></div>;
    }

    return (
        <>
            <Modal open={props.editUserDetailsModal}>
                <Box sx={style}>
                    <div className='closeheading-usermgt1'>
                        <div>User Details</div>
                        <div>
                            <CloseIcon
                                className='advisory_close_icon'
                                onClick={handleClosePopup}
                            />
                        </div>
                    </div>
                    <div>
                        <span
                            style={{
                                color: 'red',
                                fontFamily: 'appRegular',
                                fontSize: 13,
                            }}>
                            {props.errorMsgAdmin}
                        </span>
                    </div>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}
                        className='stack-usermgt-padding'>
                        <div>
                            <div className='usermgt-headerfont'>First Name</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={firstName}
                                    onChange={handleFirstNameChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>Last Name</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>Email</div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={email}
                                    onChange={handleEmailChange}
                                />
                            </div>
                        </div>
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>
                                Phone Number
                            </div>
                            <div>
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={phoneNum}
                                    onChange={handlePhoneChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>User Type</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={userType}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={userType}
                                        onChange={handleTypeChange}>
                                        {props.userTypeArray &&
                                            props.userTypeArray.map(
                                                (user: any) => (
                                                    <MenuItem
                                                        value={user}
                                                        className={
                                                            classes.menu
                                                        }>
                                                        {user}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='usermgt-headerfont'>
                                Business Unit
                            </div>
                            {buttonText === 'edit details' ? (
                                <TextField
                                    disabled={textFieldDisable}
                                    className='usermgt-textfont'
                                    InputProps={{
                                        classes: {
                                            input: props.classes.resize,
                                        },
                                    }}
                                    value={lob}
                                />
                            ) : (
                                <Select
                                    value={lob}
                                    onChange={(event: any) => {
                                        setLob(event.target.value);
                                        setRoleID('');
                                        setSelectedStateCodes([]);
                                        setSelectedLocationCodes([]);
                                        setRoleIDArray(
                                            props.valueMap?.get(
                                                event.target.value
                                            )
                                        );
                                    }}
                                    className='usermgmt-user-type-width'>
                                    <MenuItem
                                        value='select'
                                        disabled
                                        className={classes.menu}>
                                        <span className='select'>
                                            Select a Lob
                                        </span>
                                    </MenuItem>
                                    {props.lobArray &&
                                        props.lobArray.map((userlob: any) => (
                                            <MenuItem
                                                value={userlob}
                                                className={classes.menu}>
                                                {userlob}
                                            </MenuItem>
                                        ))}
                                </Select>
                            )}
                        </div>
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>Role ID</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={showRoleName()}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={roleID}
                                        onChange={(event: any) => {
                                            roleIDArray.forEach((role: any) => {
                                                if (
                                                    role.rolelabel ===
                                                    event.target.value
                                                ) {
                                                    setRoleName(
                                                        role.roledescription
                                                    );
                                                }
                                            });
                                            handleRoleChange(event);
                                        }}>
                                        {roleIDArray &&
                                            roleIDArray.map((role: any) => (
                                                <MenuItem
                                                    className={classes.menu}
                                                    value={role.rolelabel}>
                                                    {role.roledescription}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>Status</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={status}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={status}
                                        onChange={handleStatusChange}>
                                        {props.statusArray &&
                                            props.statusArray.map(
                                                (userstatus: any) => (
                                                    <MenuItem
                                                        className={classes.menu}
                                                        value={userstatus}>
                                                        {userstatus}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>
                        <div>
                            <div className='usermgt-headerfont'>
                                Channel Mode
                            </div>

                            <FormControl
                                className='usermgmt-user-type-width'
                                sx={{
                                    m: 1,
                                    height: 66,
                                    margin: 0,
                                }}>
                                <div>
                                    {buttonText === 'edit details' ? (
                                        <TextField
                                            disabled={textFieldDisable}
                                            className='usermgt-textfont'
                                            InputProps={{
                                                classes: {
                                                    input:
                                                        props.classes
                                                            .resizeChannel,
                                                },
                                            }}
                                            value={
                                                subscriptionArray
                                                    ? subscriptionArray.join(
                                                          ','
                                                      )
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <Select
                                            className='channel-width-height'
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            value={subscriptionArray}
                                            onChange={handleSubscriptionChange}
                                            input={
                                                <OutlinedInput label='Tag' />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    ? selected.join(',')
                                                    : ''
                                            }
                                            MenuProps={MenuProps}>
                                            {subscriptionarray.map((sub) => (
                                                <MenuItem
                                                    key={sub}
                                                    value={sub}
                                                    className={
                                                        classes.checkbox
                                                    }>
                                                    <Checkbox
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        checked={
                                                            subscriptionArray.indexOf(
                                                                sub
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        primary={sub}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                            </FormControl>
                        </div>
                    </Stack>

                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div className='usermgt-headerfont'>
                                Forecast Report
                            </div>

                            <FormControl
                                className='usermgmt-user-type-width'
                                sx={{
                                    height: 66,
                                    margin: 0,
                                }}>
                                <div>
                                    {buttonText === 'edit details' ? (
                                        <TextField
                                            disabled={textFieldDisable}
                                            className='usermgt-textfont'
                                            InputProps={{
                                                classes: {
                                                    input:
                                                        props.classes
                                                            .resizeChannel,
                                                },
                                            }}
                                            value={
                                                forecastArray
                                                    ? forecastArray.join(',')
                                                    : ''
                                            }
                                        />
                                    ) : (
                                        <Select
                                            className='channel-width-height'
                                            labelId='demo-multiple-checkbox-label'
                                            id='demo-multiple-checkbox'
                                            multiple
                                            value={forecastArray}
                                            onChange={handleForecastChange}
                                            input={
                                                <OutlinedInput label='Tag' />
                                            }
                                            renderValue={(selected) =>
                                                selected
                                                    ? selected.join(',')
                                                    : ''
                                            }
                                            MenuProps={MenuProps}>
                                            {forecastarray.map((fore) => (
                                                <MenuItem
                                                    className={classes.checkbox}
                                                    key={fore}
                                                    value={fore}>
                                                    <Checkbox
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        checked={
                                                            forecastArray.indexOf(
                                                                fore
                                                            ) > -1
                                                        }
                                                    />
                                                    <ListItemText
                                                        className={
                                                            classes.checkbox
                                                        }
                                                        primary={fore}
                                                    />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                </div>
                            </FormControl>
                        </div>

                        <div>
                            <div className='usermgt-headerfont'>Language</div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <TextField
                                        disabled={textFieldDisable}
                                        className='usermgt-textfont'
                                        InputProps={{
                                            classes: {
                                                input: props.classes.resize,
                                            },
                                        }}
                                        value={language}
                                    />
                                ) : (
                                    <Select
                                        className='usermgmt-user-type-width'
                                        value={language}
                                        onChange={handleLanguageChange}>
                                        {props.languageArray &&
                                            props.languageArray.map(
                                                (lang: any) => (
                                                    <MenuItem
                                                        value={lang}
                                                        className={
                                                            classes.menu
                                                        }>
                                                        {lang}
                                                    </MenuItem>
                                                )
                                            )}
                                    </Select>
                                )}
                            </div>
                        </div>

                        <div>
                            <div className='usermgt-headerfont'>
                                Change password
                            </div>
                            <div>
                                {buttonText === 'edit details' ? (
                                    <FormControl
                                        className='usermgt-password'
                                        sx={{ m: 1, margin: 0 }}
                                        variant='outlined'>
                                        <OutlinedInput
                                            id='outlined-adornment-password'
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            disabled={textFieldDisable}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge='end'>
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                ) : (
                                    <FormControl
                                        className='usermgt-password'
                                        sx={{ m: 1, margin: 0 }}
                                        variant='outlined'>
                                        <OutlinedInput
                                            id='outlined-adornment-password'
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            value={password}
                                            onChange={handlePasswordChange}
                                            endAdornment={
                                                <InputAdornment position='end'>
                                                    <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge='end'>
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                )}
                            </div>
                        </div>
                    </Stack>

                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div>
                            <div>{getLocationDropDown(roleID)}</div>
                        </div>
                    </Stack>
                    <Stack
                        className='button-edit-stack'
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={{ xs: 1, sm: 2, md: 4 }}>
                        <div className='button-editusermgt'>
                            <Button
                                className={classes.root}
                                onClick={handleClick}
                                variant='contained'
                                color={flag ? 'primary' : 'secondary'}>
                                {flag ? <EditIcon /> : <DoneIcon />}
                                &nbsp; {buttonText}
                            </Button>
                        </div>
                    </Stack>
                </Box>
            </Modal>
        </>
    );
}

export default EditUserDetails;
