/** @format */

import {
    Grid,
    TextField,
    InputLabel,
    Button,
    FormControl,
    Select,
    MenuItem,
    IconButton,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';
import './LandCropInfo.scss';
import { connect } from 'react-redux';
import {
    addInfo,
    updateAcreage,
    updateInfo,
    removeInfo,
    resetInfo,
    getCountryDetails,
} from '../../../redux/actions/createUser/create';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tableParams } from '../../../utils/helper';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { getRequest } from '../../../services/apiUtils';

import type {} from '@mui/x-date-pickers/themeAugmentation';
import { Alert } from '../../../utils/toastr';

const LandCropInfo = (props: any) => {
    const theme = createTheme({
        components: {
            MuiDatePicker: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'red',
                    },
                },
            },
        },
    });

    const[countryDropDown, setCountryDropDown] = useState<any>( props.locationMapping.countryCode);
    const [varietyDropdown, setVarietyDropdown] = useState<any>([]);
    const [seasonDropdown, setSeasonDropdown] = useState<any>([]);
    const [cropDropdown, setCropDropdown] = useState<any>([]);
    const [minDate, setMinDate] = useState<any>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [error, setError] = useState<any>(false);
    const [errtext, setErrtext] = useState<any>('');

    useEffect(() => {
        getDropdownValues();
    }, []);

  
    const getDropdownValues = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);
        let obj = {
            countrycode: props.basicInfo.countryCode,
            lob: props.locationMapping.lob,
        };
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((resp: any) => {
            setCropDropdown(resp.body.data.croplist);
            setSeasonDropdown(resp.body.data.seasonlist);
        });
    };

    const getVarietyDropdown = (cropid:any) => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let queryVariety: any = {
            countrycode: props.basicInfo.countryCode,
            lob: props.locationMapping.lob,
            loggeduserrole: loggedUser?.roleid,
            cropid:cropid ,
        };

        getRequest({
            url: 'cropcalendar/lookup/master',
            method: 'GET',
            queryParams: queryVariety,
        })
            .then((response: any) => {
                setVarietyDropdown(response.body.data.cropvariety);
            })
            .catch((error: any) => {
                Alert('error', 'Error occured in getting variety');
            });



    }


    const handlePageBack = () => {
        props.goBackOnePage(1);
    };

    const handlePageNext = () => {
        let valid = checkValidity();

        if (valid.isValid && valid.acreValid) {
            props.handleGoToNextPage(3);
        } else if (!valid.isValid) {
            setError(true);
        } else if (!valid.acreValid) {
            setErrtext('Please enter a value');
        }
    };

    const inputLength = props.landcropobj.length;

    const handleInfoChange = (input: any, label: any, i: any) => {
        const dayjs = require('dayjs');
        const dayjs_date = dayjs(input);
        const date = dayjs_date.date();
        const month = dayjs_date.month() + 1;
        const year = dayjs_date.year();
        const dateFilterValue = year + '-' + month + '-' + date;
        props.updateinfo(dateFilterValue, label, i);
    };

    const handleAddFunc = () => {
        let valid = checkValidity();

        if (valid.isValid) {
            props.addinfo();
        } else {
            setError(true);
        }
    };

    const checkValidity = () => {
        let validobj: any = {
            isValid: false,
            acreValid: false,
        };

        props.landcropobj.map((ob: any) => {
            if (
                ob.cropid !== '' &&
                ob.variety !== '' &&
                ob.season !== '' &&
                ob.acreage !== '' &&
                ob.sowingdate !== null &&
                ob.latitude !== '' &&
                ob.longitude !== '' &&
                ob.previousseasonyield !== ''
            ) {
                validobj.isValid = true;
            } else {
                validobj.isValid = false;
            }
        });

        if (props.acreVal === '') {
            validobj.acreValid = false;
        } else {
            validobj.acreValid = true;
        }

        return validobj;
    };

    const handleReset = () => {
        setError(false);
        setErrtext('');
        props.resetinfo();
    };

    return (
        <>
            <div>
                <div>
                    <div className='landCrop_header'>Land & Crop Info</div>
                    <div className='landCrop_subheader'>
                        Enter your personal information of the user you are
                        trying to create.
                    </div>
                </div>
                <div className='acreage_div'>
                    <div className='acreage_header'>Total Acerage</div>
                    <input
                        value={props.acreVal}
                        type='number'
                        className='acreage_input'
                        onChange={(ev: any) => {
                            setErrtext('');
                            props.updateAcre(ev.target.value);
                        }}
                    />
                    {errtext && (
                        <div
                            className='landCrop_error_text'
                            style={{
                                textAlign: 'right',
                                maxWidth: '250px',
                            }}>
                            {errtext}
                        </div>
                    )}
                </div>

                <div className='landCrop_input_div'>
                    <Grid container spacing={1}>
                        {props.landcropobj &&
                            props.landcropobj.map((item: any, id: any) => (
                                <>
                                    <div className='inputField_div'>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Crop
                                                    </InputLabel>
                                                </Grid>
                                            )}
                                            <Select
                                                className='landCrop_dropdown'
                                                value={item.cropid+'-'+item.cropname}
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    let cropArr = evt?.target.value.split('-')
                                                    props.updateinfo(
                                                        cropArr[0],
                                                        'Crop',
                                                        id,
                                                        cropArr[1]
                                                    );
                                                 
                                                    getVarietyDropdown(cropArr[0]);
                                                }}>
                                                {cropDropdown &&
                                                    cropDropdown.map(
                                                        (option: any) => 
                                                        option.cropid!==item.cropid 
                                                                
                                                             ?(
                                                            <MenuItem
                                                                className='landCrop_input_text'
                                                                value={
                                                                    option.cropid+'-'+option.cropname
                                                                }>
                                                                {option.cropname}
                                                            </MenuItem>
                                                            ):(
                                                            <MenuItem
                                                            disabled
                                                            value={
                                                                option.cropid+'-'+option.cropname
                                                            }>
                                                            {option.cropname}
                                                        </MenuItem>
                                                    )
                                                    )}
                                            </Select>
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Variety
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <Select
                                                className='landCrop_dropdown'
                                                value={item.variety}
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Variety',
                                                        id
                                                    );
                                                }}>
                                                <MenuItem
                                                    className='landCrop_input_text'
                                                    value={
                                                        item.variety
                                                    }></MenuItem>
                                                {varietyDropdown &&
                                                    varietyDropdown.map(
                                                        (option: any) => (
                                                            <MenuItem
                                                                className='landCrop_input_text'
                                                                value={
                                                                    option.variety
                                                                }>
                                                                {option.variety}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Season
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <Select
                                                className='landCrop_dropdown'
                                                value={item.season}
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Season',
                                                        id
                                                    );
                                                }}>
                                                <MenuItem
                                                    className='landCrop_input_text'
                                                    value={
                                                        item.season
                                                    }></MenuItem>
                                                {seasonDropdown &&
                                                    seasonDropdown.map(
                                                        (option: any) => (
                                                            <MenuItem
                                                                className='landCrop_input_text'
                                                                value={
                                                                    option.season
                                                                }>
                                                                {option.season}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Acreage
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <input
                                                className='landCrop_textfield'
                                                value={item.acreage}
                                                type='number'
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Acreage',
                                                        id
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Sowing Date
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <ThemeProvider theme={theme}>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        value={item.sowingdate}
                                                        minDate={minDate}
                                                        // inputFormat='DD MMM,YYYY'
                                                        onChange={(
                                                            newValue: any
                                                        ) => {
                                                            setError(false);
                                                            handleInfoChange(
                                                                newValue,
                                                                'Sowing Date',
                                                                id
                                                            );
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                type='tel'
                                                                //placeholder='Enter sowing Date'
                                                                title={
                                                                    item.sowingdate
                                                                }
                                                                helperText={
                                                                    null
                                                                }
                                                                sx={{
                                                                    width: 130,
                                                                    marginRight:
                                                                        '11px',
                                                                    marginTop:
                                                                        '8px',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </ThemeProvider>
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Latitude
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <input
                                                className='landCrop_textfield'
                                                value={item.latitude}
                                                type='number'
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Latitude',
                                                        id
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Longitude
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <input
                                                className='landCrop_textfield'
                                                value={item.longitude}
                                                type='number'
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Longitude',
                                                        id
                                                    );
                                                }}
                                            />
                                        </div>
                                        <div>
                                            {id === 0 && (
                                                <Grid item>
                                                    <InputLabel
                                                        sx={{
                                                            fontSize: '12px',
                                                            color: '#696969',
                                                        }}
                                                        className='landcrop_label_header'>
                                                        Prev. Season Yield (in
                                                        Tonnes/Acer)
                                                    </InputLabel>
                                                </Grid>
                                            )}

                                            <input
                                                className='landCrop_textfield'
                                                value={item.previousseasonyield}
                                                type='number'
                                                onChange={(evt: any) => {
                                                    setError(false);
                                                    props.updateinfo(
                                                        evt.target.value,
                                                        'Prev. Season Yield (in Tonnes/Acer)',
                                                        id
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div
                                        className={
                                            id === 0
                                                ? 'button_div0'
                                                : 'button_div'
                                        }>
                                        {inputLength > 1 && (
                                            <FormControl>
                                                <IconButton
                                                    onClick={() => {
                                                        setError(false);
                                                        props.removeinfo(
                                                            id,
                                                            item.cropname
                                                        );
                                                    }}>
                                                    {renderSvg(
                                                        svgIconName.remove_icon,
                                                        50
                                                    )}
                                                </IconButton>
                                            </FormControl>
                                        )}
                                        {id === 0 && inputLength === 1 && (
                                            <FormControl>
                                                <IconButton
                                                    onClick={handleAddFunc}>
                                                    {renderSvg(
                                                        svgIconName.add_icon,
                                                        50
                                                    )}
                                                </IconButton>
                                            </FormControl>
                                        )}
                                        {inputLength > 1 &&
                                            inputLength - 1 === id && (
                                                <FormControl>
                                                    <IconButton
                                                        onClick={handleAddFunc}>
                                                        {renderSvg(
                                                            svgIconName.add_icon,
                                                            50
                                                        )}
                                                    </IconButton>
                                                </FormControl>
                                            )}
                                    </div>
                                </>
                            ))}
                    </Grid>
                    {error && (
                        <div className='landCrop_error_text'>
                            'Please fill out all the fields...'
                        </div>
                    )}
                </div>
                <div className='threeButtonsContainer'>
                    <span style={{ display: 'flex' }}>
                        <span className='role_def_back_icon'>
                            {renderSvg(svgIconName.down_arrow, 10)}
                        </span>
                        <a
                            onClick={handlePageBack}
                            className='roleDef_goBack_text'>
                            Go back
                        </a>
                    </span>
                    <span className='threeButtonsReset'>
                        <Button
                            style={{
                                width: '181px',
                                height: '35px',
                            }}
                            onClick={handleReset}
                            className='roleDef_reset_btn'>
                            Reset
                        </Button>
                    </span>
                    <span>
                        <Button
                            onClick={handlePageNext}
                            style={{
                                borderRadius: '20px',
                                backgroundColor: '#7eb343',
                                width: '181px',
                                height: '35px',
                                border: 0,
                                boxShadow: '0px 3px 6px #c7c7c729',
                            }}
                            className='roleDef_next_btn'>
                            Next
                        </Button>
                    </span>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = ({
    createUser: { landCropInfo, acreage, landCropObj, selectedCrops,locationMapping,basicInfo },
}: any) => {
    return {
        basicInfo,
        locationMapping,
        landcropinfo: landCropInfo,
        acreVal: acreage,
        landcropobj: landCropObj,
        crops: selectedCrops,
    };
};

const mapDispatchToProps = {
    updateAcre: (val: any) => updateAcreage(val),
    addinfo: () => addInfo(),
    removeinfo: (id: any, crop: any) => removeInfo(id, crop),
    updateinfo: (value: any, label: any, ind: any, name?: any) =>
        updateInfo(value, label, ind, name),
    resetinfo: resetInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LandCropInfo);
