/** @format */
import React, { useState, useEffect } from 'react';
import { InputLabel, styled, InputBase, Grid, Button } from '@mui/material';
import UserGeoLevelDropdown from './UserGeoLevelDropdown';
import {
    getCountryDetails,
    locationInputValuesProvided,
    resetInfo,
    resetLocationInputs,
} from '../../../redux/actions/createUser/create';
import { renderSvg, svgIconName } from '../../../assets/img/imageExt';

import { ReactComponent as RightNext } from '../../../assets/img/svg/country-config/right_next.svg';

//Import CSS
import './LocationDetails.scss';
import { connect } from 'react-redux';
import { getRequest } from '../../../services/apiUtils';
import { getLocalStorageData } from '../../../utils/useLocalStorage';
import { FormControl, FormGroup, Divider } from '@mui/material';
import LandCropInfo from '../land-crop-info/LandCropInfo';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(0.5),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        border: '0.5px solid #919191',
        fontSize: 13,
        width: '180px',
        padding: '10px 12px',
        fontFamily: 'appMedium',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
    },
}));

function LocationDetails(props: any) {
    console.log(props.locationMapping);

    const inputFields = [
        { label: 'Address', id: 'address', type: 'text' },
        { label: 'Zip-Code', id: 'zipCode', type: 'number' },
    ];
    const [err, setErr] = useState<string>('');

    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const [lobSelected, setLobSelected] = useState<string>(
        loggedUser?.lineofbusiness
    );

    const [lobData, setLobData] = useState<any[]>([]);

    const [lobToggler, setLobToggler] = useState<boolean>(false);

    const [loadDropDown, setLoadDropDown] = useState<any>(false);

    useEffect(() => {
        getCountryData();
    }, []);

    function getCountryData() {
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: {
                countrycode: props.basicInfo.countryCode,
                lob: lobSelected,
            },
        }).then((response: any) => {
            if (response?.body?.data) {
                setLobData(response.body.data.lineofbusiness);
                setLoadDropDown(true);
            }
        });
    }

    const handlePageNext = () => {
        let emptyGeolevelsArray = props.locationMapping.allgeolevels.filter(
            (level: any) => {
                if (
                    loggedUser?.countrycode !== 'TH' &&
                    level.name !== 'geolevel4code' &&
                    (level.selectedValue === '' ||
                        level.selectedValue === 'ALL')
                ) {
                    return level.name;
                }
            }
        );
        if (
            emptyGeolevelsArray.length === 0 &&
            props.locationMapping.address.trim() !== '' &&
            props.locationMapping.zipCode !== ''
        ) {
            props.handleGoToNextPage(2);
        } else {
            setErr('*All fields are mandatory');
        }
    };
    return (
        <div>
            <div className='sub-header'> Location Mapping </div>
            <label style={{ color: '#7E7E7E' }}>
                Enter your personal information of the user you are trying to
                create.
            </label>
            <div className='containerDiv'>
               
                <div style={{ paddingBottom: '4%' }}>
                    <InputLabel className='label-header'>
                        Line Of Business
                        <span className='compulsory'>*</span>
                    </InputLabel>
                    <div
                        className='selected-value-container'
                        onClick={() => {
                            setLobToggler(!lobToggler);
                        }}>
                        <span
                            className={
                                props.locationMapping.lob === ''
                                    ? 'notSelected'
                                    : 'selected'
                            }>
                            {props.locationMapping.lob === ''
                                ? 'Please select a BU'
                                : props.locationMapping.lob}
                        </span>
                        <RightNext />
                    </div>
                    {lobToggler && (
                        <div className='dropdown-container'>
                            <FormControl>
                                <FormGroup>
                                    {lobData &&
                                        lobData.map(
                                            (lobElem: any, index: any) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div
                                                            style={{
                                                                color:
                                                                    props
                                                                        .locationMapping
                                                                        .lob ===
                                                                    lobElem
                                                                        ? '#1F4359'
                                                                        : '#A3A3A3',
                                                            }}
                                                            onClick={() => {
                                                                setLobToggler(
                                                                    !lobToggler
                                                                );

                                                                setLobSelected(
                                                                    lobElem
                                                                );

                                                                props.changeLocationInputValues(
                                                                    'lineofbusiness',
                                                                    lobElem
                                                                );
                                                            }}>
                                                            {lobElem}
                                                        </div>
                                                        {index !==
                                                            lobData.length -
                                                                1 && (
                                                            <Divider />
                                                        )}
                                                    </React.Fragment>
                                                );
                                            }
                                        )}
                                </FormGroup>
                            </FormControl>
                        </div>
                    )}
                </div>

                <Grid container spacing={4}>
                    <UserGeoLevelDropdown
                        nextButtonClicked={props.nextButtonClicked}
                        countryCodeSelected={props.basicInfo.countryCode}
                        lobSelected={lobSelected}
                        loadDropDown={loadDropDown}
                        setLoadDropDown={setLoadDropDown}
                    />
                    {inputFields.map((inputObj: any) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={inputObj.label}>
                                <InputLabel className='label-header'>
                                    {inputObj.label}
                                </InputLabel>
                                <BootstrapInput
                                    value={
                                        inputObj.id === 'address'
                                            ? props.locationMapping.address
                                            : props.locationMapping.zipCode
                                    }
                                    placeholder={`Enter the ${inputObj.label}`}
                                    onChange={(e) => {
                                        props.changeLocationInputValues(
                                            inputObj.id,
                                            e.target.value
                                        );
                                    }}
                                    className='dropdown-values'
                                    type={inputObj.type}
                                />
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
            {err && <div className='compulsory'>{err}</div>}

            <div className='threeButtonsContainer'>
                <span style={{ display: 'flex' }}>
                    <span
                        className='role_def_back_icon'
                        style={{ transform: 'rotate(90deg)' }}>
                        {renderSvg(svgIconName.down_arrow, 10)}
                    </span>
                    <a
                        onClick={() => {
                            props.goBackOnePage(0);
                        }}
                        className='roleDef_goBack_text'>
                        Go back
                    </a>
                </span>

                <span className='threeButtonsReset'>
                    <Button
                        disabled={props.disableFields}
                        style={{
                            width: '181px',
                            height: '35px',
                        }}
                        onClick={() => {
                            props.resetLocationInputs();
                        }}
                        className='roleDef_reset_btn'>
                        Reset
                    </Button>
                </span>

                <span>
                    <Button
                        onClick={handlePageNext}
                        style={{
                            borderRadius: '20px',
                            backgroundColor: '#7eb343',
                            width: '181px',
                            height: '35px',
                            border: 0,
                            boxShadow: '0px 3px 6px #c7c7c729',
                        }}
                        className='roleDef_next_btn'>
                        Next
                    </Button>
                </span>
            </div>
        </div>
    );
}

const mapStateToProps = ({
    createUser: { locationMapping, basicInfo },
}: any) => ({
    locationMapping,
    basicInfo,
});

const mapDispatchToProps = {
    getDefaultCountryDetails: (countryCode: any, countryName: any, lob: any) =>
        getCountryDetails(countryCode, countryName, lob),
    changeLocationInputValues: locationInputValuesProvided,
    resetLocationInputs,
    resetinfo: resetInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetails);
