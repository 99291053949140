/** @format */

import React, { Fragment, useState, ChangeEvent, useEffect } from 'react';
import { CustomButton } from '../../components/form/CustomButton';
import TopHeader from '../../components/layouts/TopHeader';
import Filter from '../../components/ui/Filter';
import GeoLevelDropdown from '../../components/ui/GeoLevelDropdown';
import Loader from '../../components/ui/Loader';
import {
    anyKey,
    assignedNull,
    download,
    tableParams,
    optionList,
} from '../../utils/helper';
import { getLocalStorageData } from '../../utils/useLocalStorage';
import '../User/List/index.scss';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import {
    CardContent,
    Divider,
    Grid,
    MenuItem,
    Select,
    Stack,
    TextField,
} from '@mui/material';
import FarmerImg from '../../assets/img/svg/farmer-page-icons/farmer.svg';
import LeftArrow from '../../assets/img/svg/farmer-page-icons/left_arrow.svg';
import './cropStageWise.scss';
import CropStageWise from './cropStageWise';
import { getRequest } from '../../services/apiUtils';
import { cropName, renderSvg } from '../../assets/img/imageExt';
import { Alert } from '../../utils/toastr';
import { useSearchParams } from 'react-router-dom';
import '../User/List/index.scss';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';

const CropWiseStage = () => {
    const StyledBox = styled(Box)(({ theme }) => ({
        overflowY: 'auto',
        [theme.breakpoints.up('xs')]: {
            height: '63vh',
        },
        [theme.breakpoints.up('sm')]: {
            height: '74vh',
        },
        [theme.breakpoints.up('md')]: {
            height: '78vh',
        },
        [theme.breakpoints.up('lg')]: {
            height: '64vh',
        },
        [theme.breakpoints.up('xl')]: {
            height: '85vh',
        },
    }));

    const Item = styled(Card)(({ theme }) => ({
        ...theme.typography.body2,
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        // maxWidth: 350,
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 3px 6px #00000029',
        border: '0.30000001192092896px solid #C9C9C9',
        borderRadius: 5,
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    }));

    const StyledCardHeader = styled(CardHeader)(({ theme }) => ({
        padding: theme.spacing(1),
        '& .MuiCardHeader-title': {
            color: '#000000',
            fontFamily: 'AppMedium',
            fontSize: '15px !important',
        },
        '& .MuiCardHeader-subheader': {
            color: '#0F0F0F',
            fontFamily: 'AppRegular',
            fontSize: '13px !important',
        },
        '& .MuiTypography-root': {
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.1rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '0.85rem',
            },
        },
    }));

    let closeToggle: any;
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);
    const dayjs = require('dayjs');
    const [isShowStages, setIsShowStages] = useState<any>(false);
    const [runHandleSearch, setRunHandleSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState<any>({
        geolevel0code: loggedUser?.countrycode,
        geolevel1code: 'ALL',
        geolevel2code: 'ALL',
        geolevel3code: 'ALL',
        geolevel4code: 'ALL',
        geolevel5code: null,
        lineofbusiness: loggedUser.roleid === 'SUPER_ADMIN'
        ? 'ALL': loggedUser?.lineofbusiness,
        country: loggedUser?.countrycode,
        season: 'ALL',
        seasonYear: dayjs(null),
    });
    const [errorForSeason, setErrorForSeason] = useState<any>('');
    const [errormsg, setErrormsg] = useState<any>('');
    const [minDate, setMinDate] = React.useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().subtract(1, 'year');
        return currentDate;
    });
    const [maxDate, setMaxDate] = React.useState<Dayjs>(() => {
        const dayjs = require('dayjs');
        const currentDate = dayjs().add(1, 'year');
        return currentDate;
    });
    const [yearval, setYearval] = useState<any>(dayjs(null));
    const [seasonval, setSeasonval] = useState<any>('ALL');
    const [geoLevelList, setGeoLevelList] = useState<any>([]);
    const [isClearGeoLevel, setIsClearGeoLevel] = useState<boolean>(false);
    const [applyflag, setapplyflag] = useState<any>(false);
    const [isFiltered, setIsFiltered] = useState<boolean>(false);
    const [params, setParams] = useState(tableParams());
    const [geolevel, setgeolevel] = useState<any>({ ...filter });
    const [currOptions, setcurrOptions] = useState<any>([]);
    const [isClosed, setIsClosed] = useState<any>(false);
    const [cropStageData, setCropStageData] = useState<any>([]);
    const [selectedCrop, setSelectedCrop] = useState<any>();
    const [loader, setLoader] = useState<boolean>(false);
    const [event, setEvent] = useState<any>();
    const [isApplyFilter, setIsApplyFilter] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams({});
    const specificPersona = ['TM_PL', 'FS', 'TM_SAM', 'PO', 'TL'];
    const [badgeEnabled, setBadgeEnabled] = useState<boolean>(
        loggedUser.roleid === 'SUPER_ADMIN' ? true : false
    );
    const [geoLoader, setGeoLoader] = useState<boolean>(true);
    const [lobval, setLobval] = useState<any>(
        loggedUser?.roleid === 'SUPER_ADMIN' ? 'ALL' : loggedUser.lineofbusiness
    );
    const [countryval, setCountryval] = useState<any>(loggedUser?.countrycode);
    const [lobList, setlobList] = useState<any>([
        { value: 'ALL', label: 'ALL' },
    ]);
    const [countryList, setCountryList] = useState<ChipData[]>([]);
    const [lobError, setLobError] = useState<any>(false);
    const [countryChange, setCountryChange] = useState(false);
    const [geoLevel0val, setGeolevel0val] = useState<any>(
        loggedUser?.countrycode
    );
    const arr = [1, 2, 3, 4];
    const [seasonData, setSeasonData] = useState<any>([]);

    useEffect(() => {
        getCropStageData();
        getLookup();
    }, []);

    useEffect(() => {
        getCropStageData();
    }, [params]);

    useEffect(() => {
        let selectedFilter: any = { ...filter };
        if (geoLevelList?.length) {
            geoLevelList.forEach((level: any) => {
                selectedFilter = {
                    ...selectedFilter,
                    [level.name]: level.selectedValue,
                };
            });

            setFilter({ ...selectedFilter });
        }
    }, [geoLevelList]);

    const getLookup = () => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let obj: any = {
            countrycode: loggedUser?.countrycode,
            lob:
                // loggedUser?.roleid === 'ADMIN' ||
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
        };
        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((response: any) => {
            if (response?.body?.data) {
                let countryDataOrg = response?.body?.data?.countries;

                if (countryDataOrg.length > 0) {
                    getLobLookup(loggedUser?.countrycode);
                    const filterCountry = optionList(
                        countryDataOrg,
                        'countrycode',
                        'country'
                    );
                    setCountryList([...countryList, ...filterCountry]);
                } else {
                    setCountryList([...countryList]);
                }
            }
        });
    };

    const getLobLookup = (country: any, apply?: any) => {
        let obj: any = {
            countrycode: country,
            lob:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser?.lineofbusiness,
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: obj,
        }).then((resp: any) => {
            if (resp?.body?.data) {
                let lobDataOrg = resp?.body?.data?.lineofbusiness;
                if (lobDataOrg) {
                    if (lobDataOrg.length > 0) {
                        setLobError(false);
                        const filterlob = lobDataOrg.map((lob: any) => {
                            return {
                                label: lob,
                                value: lob,
                            };
                        });
                        setlobList([
                            { label: 'ALL', value: 'ALL' },
                            ...filterlob,
                        ]);

                        if (apply) {
                            setFilter({
                                ...filter,
                                lineofbusiness: lobval,
                                country: countryval,
                                geolevel0code: geoLevel0val,
                                season: seasonval,
                                seasonYear: yearval,
                            });
                        } else {
                            setFilter({
                                ...filter,
                                lineofbusiness:
                                    loggedUser?.roleid === 'SUPER_ADMIN'
                                        ? 'ALL'
                                        : loggedUser.lineofbusiness,
                                country: country,
                                geolevel0code: country,
                                geolevel1code: 'ALL',
                                geolevel2code: 'ALL',
                                geolevel3code: 'ALL',
                                geolevel4code: 'ALL',
                                geolevel5code: null,
                                season: seasonval,
                                seasonYear: yearval,
                            });
                        }
                    }
                } else {
                    setLobError(true);
                    setlobList([{ label: 'ALL', value: 'ALL' }]);
                }
            }
        });
    };

    const getCropStageData = () => {
        setLoader(true);
        let obj: any = {
            ...params,
            countrycode: filter?.country,
            lineofbusiness:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? filter.lineofbusiness
                    : loggedUser?.lineofbusiness,
            search: search || null,
            isfiltered: isFiltered,
            loggeduserrole: loggedUser?.roleid,
        };

        if (
            specificPersona.includes(loggedUser.roleid) &&
            loggedUser.assignedloc.length > 0
        ) {
            let location = loggedUser.assignedloc.map(
                (loc: any) => loc.lochiergeocode
            );
            obj = {
                ...obj,
                ['isfiltered']: true,
                [obj.countrycode === 'TH'
                    ? 'geolevel3code'
                    : 'geolevel4code']: location.join(','),
            };
        }

        if (isFiltered) {
            // if (
            //     specificPersona.includes(loggedUser.roleid) &&
            //     loggedUser.assignedloc.length > 0
            // ) {
            //     let location = loggedUser.assignedloc.map(
            //         (loc: any) => loc.lochiergeocode
            //     );
            //     filter.geolevel4code = location.join(',');
            // }
            const condFilter = assignedNull(filter);
            obj = {
                ...obj,
                ...condFilter,
                ['lineofbusiness']: lobval,
                ['isfiltered']: true,
            };
        }

        if (!isNaN(filter.seasonYear.year())) {
            obj = {
                ...obj,
                ['year']: filter.seasonYear.year(),
            };
        }

        getRequest({
            url: 'cropstage/list',
            method: 'GET',
            queryParams: obj,
        })
            .then((response: any) => {
                if (response?.body?.data) {
                    setCropStageData(response.body.data);
                    setLoader(false);
                } else {
                    setCropStageData([]);
                    setLoader(false);
                }
            })
            .catch((err: any) => {
                Alert('error', err);
            });
    };

    const getSeasonLookup = (dateValue: any) => {
        let lsData: any = getLocalStorageData('userData');
        let loggedUser = JSON.parse(lsData);

        let seasonobj: any = {
            countrycode: filter?.country,
            lob:loggedUser?.roleid === 'SUPER_ADMIN'? 'ALL': filter?.lineofbusiness,
            year: dateValue.year(),
        };

        getRequest({
            url: 'lookup/master',
            method: 'GET',
            queryParams: seasonobj,
        }).then((res: any) => {
            if (res?.body?.data) {
                const seasonDataOrg = res.body.data.seasonlist;
                if (seasonDataOrg.length > 0 && seasonDataOrg !== 0) {
                    setErrormsg('');
                    const filt = optionList(seasonDataOrg, 'season', 'season');
                    seasonData.length === 1
                        ? setSeasonData([...seasonData, ...filt])
                        : setSeasonData([...filt]);
                } else {
                    setSeasonData([]);
                    setErrormsg('No seasons available for selected year');
                }
            }
        });
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        let searchtext = event.target.value;
        setSearch(searchtext);
        setRunHandleSearch(true);
    };

    const handleFilter = (value: any, name: string) => {
        setFilter({ ...filter, [name]: value });
    };
    const handleGetGeoLevelData = (array: any[]) => {
        setGeoLevelList([...array]);
    };

    const handleResetFilter = () => {
        setSeasonData([]);
        setSeasonval('ALL');
        setYearval(dayjs(null));
        setIsClearGeoLevel(true);
        setIsFiltered(false);
        setapplyflag(false);
        setIsApplyFilter(false);
        setBadgeEnabled(false);
        setParams(tableParams());
        setFilter({
            geolevel0code: loggedUser?.countrycode,
            geolevel1code: 'ALL',
            geolevel2code: 'ALL',
            geolevel3code: 'ALL',
            geolevel4code: 'ALL',
            geolevel5code: null,
            lineofbusiness:
                loggedUser?.roleid === 'SUPER_ADMIN'
                    ? 'ALL'
                    : loggedUser.lineofbusiness,
            country: loggedUser.countrycode,
            season: 'ALL',
            seasonYear: dayjs(null),
        });
        getLobLookup(loggedUser.countrycode);
        closeToggle && closeToggle();
        setErrorForSeason('');
        setErrormsg('');
    };
    const handleApplyFilter = (event: any) => {
        event.preventDefault();
        var error = [];

        if (
            filter.seasonYear !== null &&
            !isNaN(filter.seasonYear.year()) &&
            filter.season === 'ALL'
        ) {
            error.push('please select a season after selecting an year');
        }
        if (error.length === 0) {
            setapplyflag(true);
            setParams(tableParams());
            setIsClearGeoLevel(false);
            setIsFiltered(true);
            setIsApplyFilter(true);
            setBadgeEnabled(true);
            setLobval(filter.lineofbusiness);
            setCountryval(filter.country);
            setGeolevel0val(filter.geolevel0code);
            setSeasonval(filter.season);
            setYearval(filter.seasonYear);
            closeToggle && closeToggle();

            let selectedFilter: any = { ...filter };
            let temparr: any = [];
            if (geoLevelList?.length) {
                geoLevelList.forEach((level: any) => {
                    selectedFilter = {
                        ...selectedFilter,

                        [level.name]: level.selectedValue,
                    };
                    temparr = [...temparr, level.options];
                });
                setcurrOptions([...temparr]);
                setgeolevel({ ...selectedFilter });
            }
        } else {
            if (error.length > 0) {
                let errors = '';
                error.forEach((value: any, index: any) => {
                    if (index === error.length - 1) {
                        errors += value;
                    } else {
                        errors += value + ',';
                    }
                });
                if (!errormsg) {
                    setErrorForSeason(errors);
                    return true;
                }
            }
        }
    };

    const onCloseHandler = (node: any) => {
        closeToggle = node;
        if (!applyflag && isClosed) {
            setIsClearGeoLevel(true);
            setFilter({
                ...filter,
                geolevel0code: loggedUser?.countrycode,
                geolevel1code: 'ALL',
                geolevel2code: 'ALL',
                geolevel3code: 'ALL',
                geolevel4code: 'ALL',
                geolevel5code: null,
                lineofbusiness:
                    loggedUser?.roleid === 'SUPER_ADMIN'
                        ? 'ALL'
                        : loggedUser.lineofbusiness,
                country: loggedUser.countrycode,
                season: 'ALL',
                seasonYear: dayjs(null),
            });
            getLobLookup(loggedUser.countrycode);
            setIsClosed(false);
            setGeoLoader(true);
            setErrorForSeason('');
            setErrormsg('');
        } else if (applyflag && isClosed) {
            setFilter({
                geolevel0code: geoLevel0val,
                geolevel1code: geolevel.geolevel1code,
                geolevel2code: geolevel.geolevel2code,
                geolevel3code: geolevel.geolevel3code,
                geolevel4code: geolevel.geolevel4code,
                geolevel5code: null,
                lineofbusiness: lobval,
                country: countryval,
                season: seasonval,
                seasonYear: yearval,
            });
            geoLevelList.map((geo: any) => {
                if (geo.name === 'geolevel1code') {
                    geo.selectedValue = geolevel.geolevel1code;
                    geo.options = currOptions[0];
                }
                if (geo.name === 'geolevel2code') {
                    geo.selectedValue = geolevel.geolevel2code;
                    geo.options = currOptions[1];
                }
                if (geo.name === 'geolevel3code') {
                    geo.selectedValue = geolevel.geolevel3code;
                    geo.options = currOptions[2];
                }
                if (geo.name === 'geolevel4code') {
                    geo.selectedValue = geolevel.geolevel4code;
                    geo.options = currOptions[3];
                }
            });
            getLobLookup(countryval, true);
            setIsClosed(false);
            setGeoLoader(true);
            setErrormsg('');
            setErrorForSeason('');
        }
    };

    const closeFlagHandler = (bool: any) => {
        setIsClosed(bool);
    };

    const handleCardClick = (crop: any) => {
        setSelectedCrop(crop);
        setIsShowStages(true);
    };

    const renderCropImage = (name: any) => {
        if (name === 'chilli') {
            return renderSvg(cropName.chilli, 20);
        } else if (name === 'rice') {
            return renderSvg(cropName.rice, 20);
        } else if (name === 'tomato') {
            return renderSvg(cropName.tomato, 20);
        } else if (name === 'corn') {
            return renderSvg(cropName.corn, 20);
        } else if (name === 'capsicum') {
            return renderSvg(cropName.capsicum, 20);
        }
    };

    const keyHandler = (event: any) => {
        setEvent(event);
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
    };

    if (runHandleSearch) {
        anyKey(
            event,
            search,
            setIsApplyFilter,
            setSearchParams,
            searchParams,
            setParams
        );
        setRunHandleSearch(false);
    }

    return (
        <Fragment>
            <TopHeader>
                {!isShowStages ? (
                    <h3>CROP STAGE WISE ADVISORY</h3>
                ) : (
                    <div className='farmer-details'>
                        <img
                            src={LeftArrow}
                            onClick={() => {
                                setIsShowStages(false);
                            }}
                            alt='Left arrow'
                        />
                        <h3>CROP STAGE WISE ADVISORY</h3>
                    </div>
                )}
            </TopHeader>
            <div className='middle-container'>
                {loader ? <Loader /> : null}
                {!isShowStages && (
                    <Filter
                        isDownloadIcon={false}
                        isFilterIcon
                        placeHolder='Search a Crop'
                        searchOption1='Crop Name'
                        handleSearch={handleSearch}
                        searchText={search}
                        customHeight={450}
                        onClose={onCloseHandler}
                        closeFlag={closeFlagHandler}
                        onKeyDown={keyHandler}
                        isBadgeEnabled={badgeEnabled}
                        handleOnClickDownload={() => {
                            download(
                                search,
                                undefined,
                                'cropstage',
                                undefined,
                                'Crop'
                            );
                        }}>
                        <div>
                            <div
                                style={{
                                    color: 'red',
                                    fontSize: '13px',
                                    paddingBottom: '5px',
                                }}>
                                {errorForSeason}
                            </div>
                            {loggedUser?.roleid === 'SUPER_ADMIN' &&
                                !geoLoader && (
                                    <div className='row'>
                                        <div className='lob_topdiv'>
                                            <div>
                                                <div className='titleLabel'>
                                                    Country
                                                </div>
                                                <div
                                                    style={{
                                                        paddingTop: '5px',
                                                        padddingBottom: '5px',
                                                    }}>
                                                    <Select
                                                        className='cropstagewise_selectdiv'
                                                        // variant='standard'
                                                        defaultValue={
                                                            filter.country
                                                        }
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            setFilter({
                                                                geolevel0code:
                                                                    evt.target
                                                                        .value,
                                                                geolevel1code:
                                                                    'ALL',
                                                                geolevel2code:
                                                                    'ALL',
                                                                geolevel3code:
                                                                    'ALL',
                                                                geolevel4code:
                                                                    'ALL',
                                                                geolevel5code: null,
                                                                country:
                                                                    evt.target
                                                                        .value,
                                                                lineofbusiness:
                                                                    'ALL',
                                                                season: 'ALL',
                                                                seasonYear: dayjs(
                                                                    null
                                                                ),
                                                            });
                                                            setCountryChange(
                                                                true
                                                            );
                                                            setSeasonData([]);
                                                            setErrormsg('');
                                                            setSeasonData([]);
                                                            setSeasonval('ALL');
                                                            setYearval(
                                                                dayjs(null)
                                                            );
                                                            getLobLookup(
                                                                evt.target.value
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    '& .MuiMenuItem-root.Mui-selected': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                    '& .MuiMenuItem-root:hover': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                    '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                },
                                                            },
                                                        }}>
                                                        {countryList &&
                                                            countryList.map(
                                                                (option: any) =>
                                                                    option.value !==
                                                                    filter?.country ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.value
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>

                                            <div>
                                                <div className='titleLabel'>
                                                    Business Unit
                                                </div>
                                                <div
                                                    style={{
                                                        paddingTop: '5px',
                                                        padddingBottom: '5px',
                                                    }}>
                                                    <Select
                                                        className='cropstagewise_selectdiv'
                                                        // variant='standard'
                                                        defaultValue={
                                                            filter.lineofbusiness
                                                                ? filter.lineofbusiness
                                                                : 'ALL'
                                                        }
                                                        // style={customStyles}
                                                        size='small'
                                                        onChange={(
                                                            evt: any
                                                        ) => {
                                                            handleFilter(
                                                                evt.target
                                                                    .value,
                                                                'lineofbusiness'
                                                            );
                                                        }}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                sx: {
                                                                    '& .MuiMenuItem-root.Mui-selected': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                    '& .MuiMenuItem-root:hover': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                    '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                        backgroundColor:
                                                                            '#007aff',
                                                                        color:
                                                                            'white',
                                                                    },
                                                                },
                                                            },
                                                        }}>
                                                        {lobList &&
                                                            lobList.map(
                                                                (option: any) =>
                                                                    option.label !==
                                                                    filter?.lineofbusiness ? (
                                                                        <MenuItem
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    ) : (
                                                                        <MenuItem
                                                                            disabled
                                                                            value={
                                                                                option.label
                                                                            }>
                                                                            {
                                                                                option.label
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                            )}
                                                    </Select>
                                                </div>
                                            </div>
                                        </div>
                                        {lobError && (
                                            <div
                                                style={{
                                                    paddingBottom: '3px',
                                                    textAlign: 'right',
                                                    paddingRight: '40px',
                                                }}>
                                                <span
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '10px',
                                                        fontFamily:
                                                            'appRegular',
                                                    }}>
                                                    No LOB available for
                                                    selected country
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                )}
                            {!geoLoader && (
                                <div className='row'>
                                    <div className='season_topdiv'>
                                        <div>
                                            <div
                                                className='titleLabel'
                                                style={{
                                                    paddingBottom: '5px',
                                                }}>
                                                Calendar Year
                                            </div>
                                            <div>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}>
                                                    <DatePicker
                                                        views={['year']}
                                                        value={
                                                            yearval
                                                        }
                                                        minDate={minDate}
                                                        maxDate={maxDate}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            setFilter({
                                                                ...filter,
                                                                seasonYear: newValue,
                                                            });
                                                            getSeasonLookup(
                                                                newValue
                                                            );
                                                            setYearval(newValue)
                                                        }}
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                                className='myDatePicker'
                                                                type='tel'
                                                                helperText={
                                                                    null
                                                                }
                                                                sx={{
                                                                    width: 204,
                                                                    paddingBottom:
                                                                        '5px',
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <div className='season_divider'>
                                            <Divider
                                                className='season_divider_style'
                                                orientation='horizontal'
                                                variant='middle'
                                                flexItem
                                            />
                                        </div>
                                        <div>
                                            {errormsg.length > 0 && (
                                                <div
                                                    style={{
                                                        color: 'red',
                                                        fontSize: '13px',
                                                        paddingBottom: '5px',
                                                    }}>
                                                    {errormsg}
                                                </div>
                                            )}
                                            <div
                                                className='titleLabel'
                                                style={{
                                                    paddingBottom: '5px',
                                                }}>
                                                Season
                                            </div>
                                            <div>
                                                <Select
                                                    className='cropadv_dropdown'
                                                    // variant='standard'
                                                    defaultValue={filter.season}
                                                    // style={customStyles}
                                                    size='small'
                                                    onChange={(evt: any) => {
                                                        handleFilter(
                                                            evt.target.value,
                                                            'season'
                                                        );
                                                        setErrorForSeason('');
                                                    }}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            sx: {
                                                                '& .MuiMenuItem-root.Mui-selected': {
                                                                    backgroundColor:
                                                                        '#007aff',
                                                                    color:
                                                                        'white',
                                                                },
                                                                '& .MuiMenuItem-root:hover': {
                                                                    backgroundColor:
                                                                        '#007aff',
                                                                    color:
                                                                        'white',
                                                                },
                                                                '& .MuiMenuItem-root.Mui-selected:hover': {
                                                                    backgroundColor:
                                                                        '#007aff',
                                                                    color:
                                                                        'white',
                                                                },
                                                            },
                                                        },
                                                    }}>
                                                    {seasonData &&
                                                        seasonData.map(
                                                            (option: any) =>
                                                                option.label !==
                                                                filter?.season ? (
                                                                    <MenuItem
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                ) : (
                                                                    <MenuItem
                                                                        disabled
                                                                        value={
                                                                            option.label
                                                                        }>
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                        )}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <GeoLevelDropdown
                                filter={filter}
                                handleFilter={handleFilter}
                                getGeoLevelData={handleGetGeoLevelData}
                                geoLevelList={geoLevelList}
                                isClearGeoLevel={isClearGeoLevel}
                                handleLoader={(isLoader: boolean) =>
                                    setGeoLoader(isLoader)
                                }
                                lobSelected={filter.lineofbusiness}
                                countryChange={countryChange}
                                setCountryChange={setCountryChange}
                            />
                        </div>
                        {!geoLoader && (
                            <div className='row filterFooter'>
                                <CustomButton
                                    label={'Reset'}
                                    style={{
                                        width: '200px',
                                        height: '35px',
                                    }}
                                    className='cus-btn-filter reset'
                                    handleClick={handleResetFilter}
                                />
                                <CustomButton
                                    label={'Apply'}
                                    style={{
                                        borderRadius: '20px',
                                        backgroundColor: '#7eb343',
                                        width: '200px',
                                        height: '35px',
                                        border: 0,
                                        boxShadow: '0px 3px 6px #c7c7c729',
                                    }}
                                    className='cus-btn'
                                    handleClick={handleApplyFilter}
                                />
                            </div>
                        )}
                    </Filter>
                )}
                {isShowStages && <CropStageWise selectedcrop={selectedCrop} isFiltered={isFiltered} lob={filter.lineofbusiness} />}
                {!isShowStages &&
                    (cropStageData.length > 0 ? (
                        <Fragment>
                            <StyledBox sx={{ flexGrow: 1, mt: 1 }}>
                                <Grid
                                    container
                                    spacing={{ xs: 2, md: 3, lg: 3, xl: 3 }}
                                    columns={{
                                        xs: 2,
                                        sm: 8,
                                        md: 8,
                                        lg: 12,
                                        xl: 12,
                                    }}>
                                    {cropStageData &&
                                        cropStageData.map((stage: any) => (
                                            <Grid
                                                item
                                                xs={2}
                                                sm={4}
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                // key={index}
                                            >
                                                <Item
                                                    onClick={() =>
                                                        handleCardClick(stage)
                                                    }>
                                                    <StyledCardHeader
                                                        avatar={renderCropImage(
                                                            stage.cropname.toLowerCase()
                                                        )}
                                                        action={
                                                            <label>
                                                                <span className='cropstage_count_header'>
                                                                    Stages:
                                                                    <span className='cropstage_count'>
                                                                        {
                                                                            stage.stages
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </label>
                                                        }
                                                        title={stage.cropname}
                                                        // subheader={
                                                        //     stage.cropname
                                                        // }
                                                    />
                                                    <CardContent>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='baseline'
                                                            mb={1}
                                                            display='flex'
                                                            justifyContent='space-between'>
                                                            <span className='cropstage_count_header'>
                                                                IoT Devices :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.iotdevices
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className='cropstage_count_header'>
                                                                Focus Area :
                                                                <span className='cropstage_count'>
                                                                    {loggedUser.countrycode ===
                                                                    'TH'
                                                                        ? `${stage.focusarea} Province(s)`
                                                                        : `${stage.focusarea} State(s)`}
                                                                </span>
                                                            </span>
                                                        </Stack>
                                                        <Stack
                                                            direction='row'
                                                            spacing={2}
                                                            alignItems='baseline'
                                                            display='flex'
                                                            justifyContent='space-between'
                                                            paddingTop={1}>
                                                            <span className='cropstage_count_header'>
                                                                Monitored
                                                                Diseases :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.monitoreddiseases
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className='cropstage_count_header'>
                                                                Monitored Pests
                                                                :
                                                                <span className='cropstage_count'>
                                                                    {
                                                                        stage.monitoredpests
                                                                    }
                                                                </span>
                                                            </span>
                                                        </Stack>
                                                    </CardContent>
                                                </Item>
                                            </Grid>
                                        ))}
                                </Grid>
                            </StyledBox>
                        </Fragment>
                    ) : (
                        <div className='cropstage_error_msg'>
                            No records found!
                        </div>
                    ))}
            </div>
        </Fragment>
    );
};

export default CropWiseStage;
