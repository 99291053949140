/** @format */

import React, { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/img/illustration/dashboard-images/plus.svg';
import {
    Box,
    Button,
    Modal,
    Stack,
    TextField,
    Select,
    MenuItem,
    Accordion,
    AccordionSummary,
    FormControl,
    OutlinedInput,
    InputAdornment,
    ListItemText,
    SelectChangeEvent,
    IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import AccordionDetails from '@mui/material/AccordionDetails';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { renderSvg, svgIconName } from '../../assets/img/imageExt';
import './index.scss';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Alert } from '../../utils/toastr';
import { saveOrUpdateRequest } from '../../services/apiUtils';
import { getLocalStorageData } from '../../utils/useLocalStorage';

const useStyles = makeStyles({
    reset: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#10384f',
        color: 'white',

        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#10384f',
            color: 'white',
        },
    },
    save: {
        padding: '4px 12px',
        borderRadius: '3px',
        backgroundColor: '#2e7d32',
        color: 'white',

        '&:hover': {
            padding: '4px 12px',
            borderRadius: '3px',
            backgroundColor: '#2e7d32',
            color: 'white',
        },
    },
    menu: {
        display: 'block',
        lineHeight: '12.5px',
        padding: '4px',
        paddingLeft: '15px',
    },
    checkbox: {
        display: 'flex',
        padding: '3px',
    },
});

const style = {
    position: 'absolute' as 'absolute',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: '90%',
    top: '50%',
    left: '43%',
    transform: 'translate(-39%, -48%)',
    width: '67%',
    paddingTop: '3%',
    paddingBottom: '2%',
    overflowX: 'auto',
    borderRadius: 4,
};

const MenuProps = {
    PaperProps: {
        style: {
            height: 66,
        },
    },
};

const subscriptionarray = ['SMS', 'WatsApp'];

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

function CreateNewSuperAdmin(props: any) {
    let lsData: any = getLocalStorageData('userData');
    let loggedUser = JSON.parse(lsData);

    const classes = useStyles();

    const [open, setOpen] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [firstName, setFirstName] = useState<String>('');
    const [lastName, setLastName] = useState<String>('');
    const [email, setEmail] = useState<any>();
    const [passWord, setPassWord] = useState<String>('');
    const [userType, setUserType] = useState<any>('select');
    const [status, setStatus] = useState<String>('select');
    const [roleIDArray, setRoleIDArray] = useState<any[]>(['select']);
    const [roleDetailsArray, setRoleDetailsArray] = useState<any[]>([
        {
            country: 'select',
            lob: [],
            lobArr: [],
        },
    ]);

    const [showPassword, setShowPassword] = useState(false);

    const [double, setDouble] = useState(false);


    const createAdminData = () => {
        let assignedCountry: any[] = [];
        roleDetailsArray.forEach((countrydetail: any) => {
            assignedCountry.push({
                countrycode: countrydetail.country,
                country: props.countryMap.get(countrydetail.country),
                lineofbusiness: countrydetail.lob,
            });
        });
        let userRequest = {
            countrycode: 'NA',
            firstname: firstName,
            lastname: lastName,
            email: email,
            usertype: userType,
            defaultlanguage: 'ENGLISH',
            role: roleIDArray,
            status: status,
            password: passWord,
            assignedcountries: assignedCountry,
        };

        saveOrUpdateRequest({
            url: 'superadmin/create',
            method: 'POST',
            data: userRequest,
        }).then((response: any) => {
            if (response && response.status === 200) {
                setErrorMsg('');
                Alert('success', 'user created successfully');
                props.AdminListData();
                props.setStatusBarValue(0)
                setOpen(false);
                setFirstName('');
                setLastName('');
                setEmail('');
                setErrorMsg('');
                setPassWord('');
                setUserType('');
                setStatus('');
                setRoleIDArray([]);
                setRoleDetailsArray([
                    {
                        country: 'select',
                        lob: [],
                        lobArr: [],
                    },
                ]);
                setDouble(true);
            } else {
                Alert('error', response.message);
            }
        });
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleOpenAdminPopup = () => {
        setOpen(true);
        setDouble(false);
    };

    const handleCloseAdminPopup = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrorMsg('');
        setPassWord('');
        setStatus('select');
        setUserType('select');
        setRoleIDArray(['select']);
        setRoleDetailsArray([
            {
                country: 'select',
                lob: [],
                lobArr: [],
            },
        ]);
        setOpen(false);
        setDouble(false);
    };

    const validateError = () => {
        let error = [];
        let errorMessage = '';
        var mailReg = new RegExp(
            '^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$'
        );

        if (firstName.length === 0) {
            error.push('First name is Required');
        }

        if (lastName.length === 0) {
            error.push('Last name is Required');
        }

        if (passWord.length === 0) {
            error.push('Password is Required');
        }

        if (!mailReg.test(email) || email.length === 0) {
            error.push('Invalid EmailID');
        }

        if (userType === 'select') {
            error.push('Please Select a UserType');
        }

        if (roleIDArray.length === 0) {
            error.push('Please Select a Role');
        }

        if (status === 'select') {
            error.push('Please Select a Status');
        }

        roleDetailsArray.forEach((role: any) => {
            if (role.country === 'select') {
                error.push('Please Select a Country');
            }
            if (role.lob.length === 0) {
                error.push('Please Select a BU');
            }
        });

        error.forEach((err: string) => {
            errorMessage += err + ',';
        });

        return errorMessage;
    };

    const handleFirstName = (event: any) => {
        setFirstName(event.target.value);
    };
    const handleLastName = (event: any) => {
        setLastName(event.target.value);
    };
    const handleEmail = (event: any) => {
        setEmail(event.target.value);
    };

    const handlePassWord = (event: any) => {
        setPassWord(event.target.value);
    };

    const handleUserType = (event: any) => {
        setUserType(event.target.value);
    };

    const handleStatus = (event: any) => {
        setStatus(event.target.value);
    };

    const handleCountry = (event: any, index: any) => {
        let tempRoleDetailsArray = [...roleDetailsArray];
        tempRoleDetailsArray.forEach((roleDetail: any, i: any) => {
            let temproleDetail = { ...roleDetail };
            if (index === i) {
                temproleDetail.country = event.target.value;
                temproleDetail.lobArr = props.lobMap.get(event.target.value);
                temproleDetail.lob = [];
            }
            tempRoleDetailsArray[i] = temproleDetail;
        });
        setRoleDetailsArray(tempRoleDetailsArray);
    };

    const handleLobChange = (event: any, index: any) => {
        let tempRoleDetailsArray = [...roleDetailsArray];
        tempRoleDetailsArray.forEach((roleDetail: any, i: any) => {
            let temproleDetail = { ...roleDetail };
            if (index === i) {
                temproleDetail.lob = event.target.value;
            }
            tempRoleDetailsArray[i] = temproleDetail;
        });
        setRoleDetailsArray(tempRoleDetailsArray);
    };

    const handleRoleChange = (event: any) => {
        setRoleIDArray(event.target.value);
    };

    const handleAddRow = () => {
        let tempRoleDetailArray = [...roleDetailsArray];
        tempRoleDetailArray.push({
            country: 'select',
            lob: [],
            lobArr: [],
        });
        setRoleDetailsArray(tempRoleDetailArray);
    };

    const handleRemoveRow = () => {
        let tempRoleDetailArray = [...roleDetailsArray];
        tempRoleDetailArray.pop();
        setRoleDetailsArray(tempRoleDetailArray);
    };

    const handleReset = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setErrorMsg('');
        setPassWord('');
        setUserType('');
        setStatus('');
        setRoleIDArray([]);
        setRoleDetailsArray([
            {
                country: 'select',
                lob: [],
                lobArr: [],
            },
        ]);
        setDouble(false);
    };

    const handleCreate = () => {
        let errorMessage = validateError();
        if (errorMessage === '') {
            createAdminData();
            setDouble(true);
        } else {
            setErrorMsg(errorMessage);
        }
    };

    return (
        <div className='usermgt-createnew'>
            <div className='modal-advisory-button'>
                <Button
                    className='advisory-new-button'
                    onClick={handleOpenAdminPopup}>
                    <PlusIcon className='plus-advisory-icon' width={15} />
                    &nbsp;&nbsp;Create Super Admin
                </Button>

                <Modal open={open}>
                    <Box sx={style}>
                        <div className='closeheading-usermgt'>
                            <div>Create SuperAdmin</div>
                            <div>
                                <CloseIcon
                                    className='advisory_close_icon'
                                    onClick={handleCloseAdminPopup}
                                />
                            </div>
                        </div>
                        <div>
                            <span
                                style={{
                                    color: 'red',
                                    fontFamily: 'appRegular',
                                    fontSize: 13,
                                    paddingBottom: 4,
                                }}>
                                {errorMsg}
                            </span>
                        </div>

                        <Accordion>
                            <div className='accordian-usermgt'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography sx={{ color: '#10384f' }}>
                                        User Details
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            First Name
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleFirstName}
                                                value={firstName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Last Name
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleLastName}
                                                value={lastName}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Email
                                        </div>
                                        <div>
                                            <TextField
                                                className='usermgt-textfont'
                                                InputProps={{
                                                    classes: {
                                                        input:
                                                            props.classes
                                                                .resize,
                                                    },
                                                }}
                                                onChange={handleEmail}
                                                value={email}
                                            />
                                        </div>
                                    </div>
                                </Stack>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4, lg: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Password
                                        </div>
                                        <div>
                                            <FormControl
                                                className='create-usermgt-pass'
                                                sx={{
                                                    m: 1,
                                                    margin: 0,
                                                }}
                                                variant='outlined'>
                                                <OutlinedInput
                                                    id='outlined-adornment-password'
                                                    type={
                                                        showPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    onChange={handlePassWord}
                                                    value={passWord}
                                                    endAdornment={
                                                        <InputAdornment position='end'>
                                                            <IconButton
                                                                aria-label='toggle password visibility'
                                                                onClick={
                                                                    handleClickShowPassword
                                                                }
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge='end'>
                                                                {showPassword ? (
                                                                    <VisibilityOff />
                                                                ) : (
                                                                    <Visibility />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            User Type
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={userType}
                                                onChange={handleUserType}>
                                                <MenuItem  className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a UserType
                                                    </span>
                                                </MenuItem>
                                                {props.userTypeArray &&
                                                    props.userTypeArray.map(
                                                        (usertype: any) => (
                                                            <MenuItem  className={classes.menu}
                                                                value={
                                                                    usertype
                                                                }>
                                                                {usertype}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Status
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={status}
                                                onChange={handleStatus}>
                                                <MenuItem  className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Status
                                                    </span>
                                                </MenuItem>

                                                {props.statusArray &&
                                                    props.statusArray.map(
                                                        (userstatus: any) => (
                                                            <MenuItem  className={classes.menu}
                                                                value={
                                                                    userstatus
                                                                }>
                                                                {userstatus}
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                </Stack>
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'column',
                                        md: 'row',
                                    }}
                                    spacing={{ xs: 1, sm: 2, md: 4 }}>
                                    <div>
                                        <div className='usermgt-headerfont'>
                                            Role
                                        </div>
                                        <div>
                                            <Select
                                                className='usermgmt-user-type-width'
                                                value={roleIDArray}
                                                onChange={handleRoleChange}>
                                                <MenuItem  className={classes.menu}
                                                    value='select'
                                                    disabled>
                                                    <span className='select'>
                                                        Select a Role
                                                    </span>
                                                </MenuItem>
                                                {props.roleAdminArray &&
                                                    props.roleAdminArray.map(
                                                        (role: any) => (
                                                            <MenuItem  className={classes.menu}
                                                                value={
                                                                    role.rolelabel
                                                                }>
                                                                {
                                                                    role.description
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                            </Select>
                                        </div>
                                    </div>
                                </Stack>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion>
                            <div className='accordian-usermgt'>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls='panel1a-content'
                                    id='panel1a-header'>
                                    <Typography sx={{ color: '#10384f' }}>
                                        Country and BU
                                    </Typography>
                                </AccordionSummary>
                            </div>
                            <AccordionDetails>
                                <div className='header-user-font'>
                                    <div className='usermgt-headerfont'>
                                        Country
                                    </div>
                                    <div className='usermgt-headerfont'>
                                        Business Unit
                                    </div>
                                </div>
                                {roleDetailsArray &&
                                    roleDetailsArray.map(
                                        (roledetail: any, index: any) => (
                                            <div>
                                                <Stack
                                                    direction={{
                                                        xs: 'column',
                                                        sm: 'column',
                                                        md: 'row',
                                                    }}
                                                    spacing={{
                                                        xs: 1,
                                                        sm: 2,
                                                        md: 4,
                                                    }}
                                                    className='stack-country-gap'>
                                                    <div>
                                                        <Select
                                                            className='usermgmt-user-type-width'
                                                            value={
                                                                roledetail.country
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) =>
                                                                handleCountry(
                                                                    event,
                                                                    index
                                                                )
                                                            }>
                                                            <MenuItem  className={classes.menu}
                                                                value='select'
                                                                disabled>
                                                                <span className='select'>
                                                                    Select a
                                                                    Country
                                                                </span>
                                                            </MenuItem>
                                                            {props.countryAdminArray &&
                                                                props.countryAdminArray.map(
                                                                    (
                                                                        countrylist: any
                                                                    ) => (
                                                                        <MenuItem  className={classes.menu}
                                                                            value={
                                                                                countrylist.countrycode
                                                                            }>
                                                                            {
                                                                                countrylist.country
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </div>
                                                    <div>
                                                        <Select
                                                            className='usermgmt-user-type-width'
                                                            multiple
                                                            value={
                                                                roledetail.lob
                                                            }
                                                            renderValue={(
                                                                selected
                                                            ) =>
                                                                selected.join(
                                                                    ', '
                                                                )
                                                            }
                                                            onChange={(
                                                                event: any
                                                            ) =>
                                                                handleLobChange(
                                                                    event,
                                                                    index
                                                                )
                                                            }>
                                                            <MenuItem  className={classes.checkbox}
                                                                value='select'
                                                                disabled>
                                                                <span className='select'>
                                                                    Select BU
                                                                </span>
                                                            </MenuItem>
                                                            {roledetail.lobArr &&
                                                                roledetail.lobArr.map(
                                                                    (
                                                                        lobadmin: any
                                                                    ) => (
                                                                        <MenuItem  className={classes.checkbox}
                                                                            value={
                                                                                lobadmin
                                                                            }>
                                                                            <Checkbox
                                                                                icon={
                                                                                    icon
                                                                                }
                                                                                checkedIcon={
                                                                                    checkedIcon
                                                                                }
                                                                                checked={
                                                                                    roledetail.lob.indexOf(
                                                                                        lobadmin
                                                                                    ) >
                                                                                    -1
                                                                                }
                                                                            />
                                                                            {
                                                                                lobadmin
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </div>
                                                    {index ===
                                                        roleDetailsArray.length -
                                                            1 && (
                                                        <div>
                                                            <IconButton
                                                                onClick={
                                                                    handleAddRow
                                                                }>
                                                                {renderSvg(
                                                                    svgIconName.add_icon,
                                                                    50
                                                                )}
                                                            </IconButton>

                                                            {index !== 0 && (
                                                                <IconButton
                                                                    onClick={
                                                                        handleRemoveRow
                                                                    }>
                                                                    {renderSvg(
                                                                        svgIconName.remove_icon,
                                                                        50
                                                                    )}
                                                                </IconButton>
                                                            )}
                                                        </div>
                                                    )}
                                                </Stack>
                                            </div>
                                        )
                                    )}
                            </AccordionDetails>
                        </Accordion>
                        <div className='button-usermgt'>
                            <Button
                                className={classes.reset}
                                variant='outlined'
                                onClick={handleReset}>
                                Reset
                            </Button>
                            <Button
                                className={classes.save}
                                disabled={double}
                                variant='contained'
                                color='success'
                                onClick={handleCreate}>
                                Save
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </div>
    );
}

export default CreateNewSuperAdmin;
